import React from "react";
import "../../assets/styling/bizreport.css";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

const AnimatedArrows = () => {
  const arrow_size = 50;
  const arrow_color = "#f5f5f5";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "0",
        marginTop: "0",
        background: "linear-gradient(0deg, #448db5 0%, #5971ab 60%)",
        borderRadius: "20px",
        width: "55px",
        margin: "auto",
      }}
    >
      <div className="biz-report-arrow" style={{ marginTop: "0px" }}>
        <KeyboardArrowDownRoundedIcon
          sx={{ fontSize: arrow_size, color: arrow_color }}
          className="biz-report-arrow-icon"
        />
      </div>
      <div className="biz-report-arrow">
        <KeyboardArrowDownRoundedIcon
          sx={{ fontSize: arrow_size, color: arrow_color }}
          className="biz-report-arrow-icon"
        />
      </div>
      <div className="biz-report-arrow">
        <KeyboardArrowDownRoundedIcon
          sx={{ fontSize: arrow_size, color: arrow_color }}
          className="biz-report-arrow-icon"
        />
      </div>
    </div>
  );
};

export default AnimatedArrows;
