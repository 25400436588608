import React from "react";
export const bold_text = (text) => {
  const parts = text.split(/\[(.*?)\]/);
  return parts.map((part, i) =>
    i % 2 === 0 ? (
      part
    ) : (
      <span key={i} className="bold-text">
        {part}
      </span>
    )
  );
};
