import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import QuizActive from "./QuizActive";
import Loader from "../Loader";
import axios from "axios";
import FaqSection from "../Playbook/FaqSection";

const QuizHolder = ({ version, campaign }) => {
  const [isLoading, setLoading] = useState(false);
  const [quiz_questions, setQuestions] = useState({});
  const [quiz_active, setActive] = useState(true);

  useEffect(() => {
    setLoading(true);
    loadQuestions();
  }, []);

  const showFAQ = () => {
    setActive(false);
  };

  const loadQuestions = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/api/users/biz-quiz-qs");
      const loaded_qs = res.data.questions;
      setQuestions(loaded_qs);

      // Preload the first question's image and wait for it to load
      const firstQuestion = loaded_qs[0]; // Adjust if your questions are not zero-indexed
      if (
        firstQuestion &&
        firstQuestion.images &&
        firstQuestion.images.length > 0
      ) {
        const firstImageUrl = firstQuestion.images[0];

        await preloadImage(firstImageUrl);

        // Optionally start preloading the second image
        const secondQuestion = loaded_qs[1];
        if (
          secondQuestion &&
          secondQuestion.images &&
          secondQuestion.images.length > 0
        ) {
          const secondImageUrl = secondQuestion.images[0];
          preloadImage(secondImageUrl); // Preload without waiting
        }
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const preloadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = resolve;
      img.onerror = resolve; // Resolve even on error to avoid blocking
      img.src = url;
    });
  };
  if (isLoading) {
    return <Loader h={"12em"} m={"4em 0 0"} w={"100%"} />;
  }
  return (
    <div>
      <div className="demo-dot">
        <div className={quiz_active ? "customize-box" : "result-box"}>
          <CssBaseline />
          <QuizActive
            // uploadUserPref={uploadUserPref}
            questions={quiz_questions}
            showFAQ={showFAQ}
            version={version}
            campaign={campaign}
          />
        </div>
      </div>
      {/* {console.log("quiz state active: " + quiz_active)} */}
      {quiz_active ? null : (
        <div style={{ marginTop: "4rem" }}>
          <hr />
          {/* <FaqSection version={"plbai"} /> */}
        </div>
      )}
    </div>
  );
};

export default QuizHolder;
