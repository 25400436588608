import React, { useState } from "react";
import FaqItem from "./FaqItem";

const quiz_faq = [
  {
    question: "What exactly will I receive?",
    answer:
      "You'll receive weekly personalized business trend reports tailored to your unique skillset. Each report identifies emerging market opportunities that match your strengths and includes actionable launch plans to help you capitalize on these trends. We analyze your profile across multiple dimensions to ensure the opportunities we suggest align perfectly with your capabilities.",
  },
  {
    question: "How does the personalization work?",
    answer:
      "Based on your initial skills assessment, we match emerging business trends with your specific strengths and experience. This means you'll only receive opportunities that you're well-positioned to pursue, along with customized launch strategies that leverage your existing skills.",
  },
  {
    question: "What's the special pre-launch offer?",
    answer:
      "Sign up now to lock in your first month for just $1! Regular pricing is $5 per month thereafter.",
  },
  {
    question: "Can I cancel my subscription?",
    answer:
      "Absolutely! You can cancel your subscription at any time. If you're not satisfied within the first 7 days, we'll provide a full refund. After that, you can still cancel anytime",
  },
  {
    question: "How practical are the business opportunities you suggest?",
    answer:
      "Every opportunity we suggest is carefully vetted for feasibility and matched to your capabilities. We focus on emerging trends with real profit potential and provide detailed launch plans that break down exactly how to get started using your existing skills.",
  },
  {
    question: "How often will I receive reports?",
    answer:
      "You'll receive a new personalized report every week, delivered straight to your inbox. Each report includes fresh opportunities and actionable strategies based on the latest market trends and your skill profile.",
  },
  {
    question: "What if I need help understanding my report?",
    answer:
      "We're here to help! If you have questions about your report or need clarification, just email us at support@thebossbrief.com.",
  },

  {
    question: "Will I need any special tools or resources?",
    answer:
      "Our launch plans are designed to work with commonly available tools and resources. We'll clearly outline any necessary resources in each report, focusing on accessible and cost-effective solutions.",
  },
];

const nl_faq = [
  {
    question: "When do you send the newsletter?",
    answer:
      "You can expect the newsletter at around the morning time in the EST time zone.",
  },
  {
    question: "I'm not seeing the newsletter in my inbox, what should I do?",
    answer:
      "To make sure our emails get delivered, be sure to add brief@thebossbrief.com to your contacts. Please also check your spam folders to make sure we're not in there.",
  },
  {
    question: "Where can I go to Unsubscribe?",
    answer:
      "If you ever get tired of us, feel free to hit the unsubscribe link added to the bottom of each newsletter.",
  },
  {
    question: "How can I submit feedback?",
    answer:
      "You can find a feedback option at the bottom of every newsletter. However if you want to get in contact directly feel free to reach out to Mr. Brief at: brief@thebossbrief.com",
  },
  {
    question: "Where can I find older newsletters?",
    answer: "The archive page is coming soon! Please check back later ☺️",
  },
];

const FaqSection = ({ nl }) => {
  const [openIndex, setOpenIndex] = useState(null);
  let faqUse = quiz_faq;
  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const NLSection = () => {
    return (
      <div>
        <div
          style={{ textAlign: "center", marginTop: "3rem" }}
          className="section-title"
        >
          Leadership Newsletter FAQs
        </div>
        {nl_faq.map((item, index) => (
          <FaqItem
            key={index}
            isOpen={openIndex === index}
            onClick={() => handleToggle(index)}
            question={item.question}
            answer={item.answer}
          />
        ))}
      </div>
    );
  };
  return (
    <div className="ai-faq">
      <div style={{ textAlign: "center" }} className="section-title">
        {nl ? "Business Report FAQs" : "Frequently Asked Questions"}
      </div>
      {faqUse.map((item, index) => (
        <FaqItem
          key={index}
          isOpen={openIndex === index}
          onClick={() => handleToggle(index)}
          question={item.question}
          answer={item.answer}
        />
      ))}
      {/* {nl ? <NLSection /> : null} */}
    </div>
  );
};

export default FaqSection;
