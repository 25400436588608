import React, { useState, useEffect } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import "../../assets/styling/launchplan.css";

import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import PreviewRoundedIcon from "@mui/icons-material/PreviewRounded";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import AdsClickRoundedIcon from "@mui/icons-material/AdsClickRounded";
import StraightenRoundedIcon from "@mui/icons-material/StraightenRounded";
import TroubleshootRoundedIcon from "@mui/icons-material/TroubleshootRounded";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import RecentActorsRoundedIcon from "@mui/icons-material/RecentActorsRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import HighlightAltRoundedIcon from "@mui/icons-material/HighlightAltRounded";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const LaunchPlan = () => {
  const [activeStep, setActiveStep] = useState(null);
  const [plan, setPlan] = useState(null);
  const [userToken, setUtk] = useState("");
  const [verifyError, setVerifyError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    "Something went wrong ☹️ Please check back later. Or you can contact us at support@thebossbrief.com"
  );
  const no_utk =
    "Hey there 👋, You need to be a member of The Boss Brief to see our business plans. If you are a subscriber perhaps there's an issue with your link. Try clicking the link in the last email we sent.";
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    verifyUser(params.get("utk"), params.get("plan_id"));
  }, []);

  const verifyUser = async (utk, plan_id) => {
    try {
      if (utk && plan_id) {
        setUtk(utk);
        const response = await axios.post("/api/report/launch-plan/verify", {
          utk,
          plan_id,
        });

        if (response && response.data) {
          if (response.data.error === true) {
            setVerifyError(true);
            setErrorMsg(response.data.error_msg);
            return;
          }
          if (response.data.plan_content) {
            setPlan(response.data.plan_content);
          } else {
            setVerifyError(true);
            setErrorMsg("No plan content available");
          }
        } else {
          setVerifyError(true);
        }
      } else {
        setVerifyError(true);
        setErrorMsg(no_utk);
      }
    } catch (error) {
      setVerifyError(true);
      if (error.response && error.response.status === 429) {
        setErrorMsg(
          "Whoa there! 😅 You've made too many requests. Please wait a few minutes and try again."
        );
      } else {
        console.error("Verification error:", error);
      }
    }
  };

  const toggleStep = (index) => {
    setActiveStep(activeStep === index ? null : index);

    // Add scroll behavior after state update
    if (activeStep !== index) {
      setTimeout(() => {
        const stepElement = document.querySelector(`[data-step="${index}"]`);
        if (stepElement) {
          stepElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 0);
    }
  };
  const step_icons_size = "28px";
  const plan_icons = {
    website: <PreviewRoundedIcon sx={{ fontSize: step_icons_size }} />,
    email: <EmailRoundedIcon sx={{ fontSize: step_icons_size }} />,
    promote: <CampaignRoundedIcon sx={{ fontSize: step_icons_size }} />,
    ads: <AdsClickRoundedIcon sx={{ fontSize: step_icons_size }} />,
    measure: <StraightenRoundedIcon sx={{ fontSize: step_icons_size }} />,
    analyze: <TroubleshootRoundedIcon sx={{ fontSize: step_icons_size }} />,
    money: <CreditCardRoundedIcon sx={{ fontSize: step_icons_size }} />,
    highlight: <HighlightAltRoundedIcon sx={{ fontSize: step_icons_size }} />,
    edit: <EditNoteRoundedIcon sx={{ fontSize: step_icons_size }} />,
    signup: <RecentActorsRoundedIcon sx={{ fontSize: step_icons_size }} />,
    offer: <LocalOfferRoundedIcon sx={{ fontSize: step_icons_size }} />,
  };

  const getIcon = (iconName) => {
    return plan_icons[iconName] || <PreviewRoundedIcon />;
  };
  const getPlanDifficulty = (plan_steps) => {
    if (!plan_steps || plan_steps.length === 0) return "Unknown";

    let totalDifficulty = 0;
    let totalSubsteps = 0;

    plan_steps.forEach((step) => {
      if (step.details) {
        step.details.forEach((detail) => {
          if (detail.substep_difficulty) {
            totalDifficulty += parseInt(detail.substep_difficulty);
            totalSubsteps++;
          }
        });
      }
    });

    if (totalSubsteps === 0) return "Unknown";

    const averageDifficulty = Math.round(totalDifficulty / totalSubsteps);
    const difficultyPositions = {
      1: 20, // Easy - far left
      2: 40, // Moderate - quarter
      3: 60, // Medium - middle
      4: 80, // Challenging - three quarters
      5: 100, // Hard - almost far right
    };

    return {
      text: difficulty_meter[averageDifficulty] || "Unknown",
      position: difficultyPositions[averageDifficulty] || 50,
    };
  };
  const difficulty_meter = {
    1: "Easy",
    2: "Moderate",
    3: "Intermediate",
    4: "Challenging",
    5: "Hard",
  };

  const handleCloseError = () => {
    setVerifyError(false);
    navigate("/");
  };

  if (!plan) {
    return (
      <Dialog
        open={verifyError}
        onClose={handleCloseError}
        PaperProps={{ className: "launch-plan-modal" }}
      >
        <DialogTitle className="launch-plan-modal-title">
          {errorMsg}
        </DialogTitle>
        <DialogActions className="launch-plan-modal-actions">
          <Button
            onClick={handleCloseError}
            className="launch-plan-modal-button"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <div className="launch-plan-container">
      <div className="launch-plan-header">
        <div className="launch-plan-header-content">
          <div className="launch-plan-header-left">
            <img
              src={plan.plan_image || "default-image-url"}
              alt="Plan Icon"
              className="launch-plan-header-image"
            />
            <h1 className="launch-plan-header-title">{plan.plan_name}</h1>
          </div>

          <div className="launch-plan-header-details">
            <div className="launch-plan-header-item">
              <span className="launch-plan-header-label">PLAN TYPE</span>
              <span className="launch-plan-header-value">{plan.plan_type}</span>
            </div>

            <div className="launch-plan-header-item">
              <span className="launch-plan-header-label">PLAN DURATION</span>
              <span className="launch-plan-header-value">
                {plan.plan_duration}
              </span>
            </div>

            <div className="launch-plan-header-item">
              <span className="launch-plan-header-label">PLAN DIFFICULTY</span>
              <span className="launch-plan-header-value">
                {getPlanDifficulty(plan.plan_steps).text}
              </span>
              <div className="launch-plan-difficulty-meter">
                <div
                  className="launch-plan-difficulty-indicator"
                  style={{
                    left: `${getPlanDifficulty(plan.plan_steps).position}%`,
                  }}
                ></div>
              </div>
            </div>

            <div className="launch-plan-header-item">
              <span className="launch-plan-header-label">PLAN COST</span>
              <span className="launch-plan-header-value">{plan.plan_cost}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="launch-plan-section">
        <hr />
        <div className="launch-plan-steps">
          {plan.plan_steps.map((step, index) => (
            <div
              key={index}
              data-step={index}
              className={`launch-plan-step ${
                activeStep === index ? "active" : ""
              }`}
              onClick={() => toggleStep(index)}
            >
              <div className="launch-plan-step-header">
                <div className="launch-plan-step-info">
                  {step.icon && (
                    <span className="launch-plan-icon">
                      {getIcon(step.icon)}
                    </span>
                  )}
                  <div className="launch-plan-text">
                    <div className="launch-plan-name">
                      {index + 1}. {step.name || "Step Name"}
                    </div>
                    <div className="launch-plan-subheader">
                      {step.subheader || "Subheader"}
                    </div>
                  </div>
                </div>
                {activeStep === index ? (
                  <KeyboardArrowUpRoundedIcon />
                ) : (
                  <KeyboardArrowDownRoundedIcon />
                )}
              </div>
              {activeStep === index && (
                <div className="launch-plan-content">
                  {step.details.map((detail, detailIndex) => (
                    <div key={detailIndex} className="launch-plan-sub-step">
                      <div className="launch-plan-sub-header">
                        <StarRateRoundedIcon className="launch-plan-sub-icon" />
                        <h3 className="launch-plan-sub-title">
                          {detail.substep_name}
                        </h3>
                      </div>
                      <p className="launch-plan-sub-description">
                        {detail.description}
                      </p>

                      {detail.demo && (
                        <div className="launch-plan-sub-demo">
                          <img
                            src={detail.demo}
                            alt="Step demonstration"
                            className="launch-plan-sub-demo-image"
                          />
                        </div>
                      )}

                      <div className="launch-plan-sub-metadata">
                        <div className="launch-plan-sub-metric">
                          <span className="launch-plan-sub-label">
                            Duration:
                          </span>
                          <span className="launch-plan-sub-value">
                            {detail.substep_duration}
                          </span>
                        </div>
                        <div className="launch-plan-sub-metric">
                          <span className="launch-plan-sub-label">Cost:</span>
                          <span className="launch-plan-sub-value">
                            {detail.substep_cost}
                          </span>
                        </div>
                        <div className="launch-plan-sub-metric">
                          <span className="launch-plan-sub-label">
                            Difficulty:
                          </span>
                          <span className="launch-plan-sub-value">
                            {difficulty_meter[detail.substep_difficulty]}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LaunchPlan;
