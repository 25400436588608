import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NextWeekIcon from "@mui/icons-material/NextWeek";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import HelpIcon from "@mui/icons-material/Help";
import { color_scheme } from "../../utils/helpful";
import { preorder_date } from "../../utils/quiz_page_rotation";
import Loader from "../../components/Loader";
import axios from "axios";
const ConfirmationQ = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [email, setEmail] = useState("email");
  const [order_date, setOrderDate] = useState("order date");
  const [loading, setLoading] = useState(false);
  const [default_display, setDisplayDefault] = useState(false);
  const thank_you_msg =
    "Congratulations on successfully preordering your Weekly Business Report! We're thrilled to have you on board and can't wait to help you discover the online business that's perfectly aligned with your strengths and goals.";
  const thank_you_no_deets =
    "Congratulations on successfully preordering your Weekly Business Report! We're thrilled to have you on board! Please check your email for a confirmation message with your order details.";
  const thank_you_header = "Welcome to The Boss Brief!";
  const signoff =
    "We can't wait to help you discover and launch your perfect business venture!";
  // Ref to track if effect has run
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      const param1 = searchParams.get("session"); //stripe session

      if (param1) {
        checkOrder(param1);
      } else {
        setDisplayDefault(false);
      }
      // Set the ref to true after running
      effectRan.current = true;
    }
  }, []);

  const checkOrder = async (session) => {
    try {
      setLoading(true);

      const res = await axios.post("/api/users/report-order", {
        session,
        preorder_date,
      });
      const order_details = res.data.orderDetails;
      setEmail(order_details.email);
      setOrderDate(
        new Date(order_details.order_date * 1000).toLocaleDateString()
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      //   console.log(err);
      setDisplayDefault(true);
      console.log("Processing order");
      alert(
        "Your confirmation email should arrive shortly. If you don't see it, please reach out to support@thebossbrief.com."
      );
    }
  };

  if (default_display)
    return (
      <div className="ty-box">
        {/* Rounded Box */}

        <div>
          <div className="confirm-title">{thank_you_header}</div>
          <p className="confirm-msg">
            <CheckCircleIcon sx={{ fontSize: 25, color: "#5bc699" }} />
            {thank_you_no_deets}
          </p>
          <div>
            <img
              style={{
                width: "100%",
                height: "200px",
                objectFit: "contain",
                margin: "0rem auto",
              }}
              src="https://d2oa23hr7q6t96.cloudfront.net/playbook/fullreport_sm.gif"
            />
          </div>
        </div>
      </div>
    );

  return (
    <div className="ty-box">
      {/* Rounded Box */}
      {loading ? (
        <Loader h={"12em"} m={"auto"} w={"80%"} />
      ) : (
        <div>
          <div className="confirm-title">{thank_you_header}</div>
          <p className="confirm-msg">
            <CheckCircleIcon sx={{ fontSize: 25, color: "#5bc699" }} />
            {thank_you_msg}
          </p>
          <div>
            <img
              style={{
                width: "100%",
                height: "200px",
                objectFit: "contain",
                margin: "0rem auto",
              }}
              src="https://d2oa23hr7q6t96.cloudfront.net/playbook/fullreport_sm.gif"
            />
          </div>

          <div className="ty-detail-box">
            <div className="ty-section">
              <span>
                <FormatListBulletedIcon
                  className="ty-icon"
                  sx={{ fontSize: 20, color: color_scheme.lighter_version }}
                />
              </span>
              <div className="ty-title">Key Details</div>
            </div>
            <div className="ty-section-indent">
              <p style={{ marginBottom: "0.2rem" }}>
                <span
                  style={{
                    borderBottom: "2px solid #707070",
                    lineHeight: "1",
                    fontSize: "1.09rem",
                  }}
                  className="ty-item"
                >
                  Weekly Business Trend Report
                </span>
              </p>
              <p style={{ margin: "0" }}>
                <span className="ty-item">Ordered On:</span>
                <span className="ty-detail">{order_date}</span>
              </p>
              <p style={{ margin: "0" }}>
                <span className="ty-item">Delivery Email:</span>
                <span className="ty-detail">{email}</span>
              </p>
              <p style={{ margin: "0" }}>
                <span className="ty-item">First Report:</span>
                <span className="ty-detail">{preorder_date}</span>
              </p>
              <p style={{ margin: "0" }}>
                <span className="ty-item">Trial Period:</span>
                <span className="ty-detail">30 days</span>
              </p>
            </div>

            <div className="ty-section">
              <span>
                <NextWeekIcon
                  className="ty-icon"
                  sx={{ fontSize: 20, color: color_scheme.lighter_version }}
                />
              </span>
              <div className="ty-title">What's Next?</div>
            </div>
            <div className="ty-section-indent">
              <p>
                We've sent a confirmation email to <b>{email}</b> to confirm
                your preorder. Please allow up to 30 minutes for this email to
                arrive. If you don't see it, be sure to check your spam or junk
                folder in case it ended up there. Once {preorder_date} arrives,
                we'll send a separate email with your first report.
              </p>
            </div>

            <div className="ty-section">
              <span>
                <HelpIcon
                  className="ty-icon"
                  sx={{ fontSize: 20, color: color_scheme.lighter_version }}
                />
              </span>
              <div className="ty-title">Have Questions?</div>
            </div>
            <div className="ty-section-indent">
              <p>
                We're here to help! If your confirmation email takes longer than
                30 minutes to arrive or you encounter any other issues, feel
                free to reach out to us at{" "}
                <a href="mailto:support@thebossbrief.com">
                  support@thebossbrief.com
                </a>
                . We'll be happy to assist you. You can also explore our{" "}
                <a href="https://www.thebossbrief.com/faq">FAQ page</a> for more
                information about your subscription and our weekly reports.
              </p>
            </div>

            <p style={{ marginTop: "2rem" }}>{signoff}</p>
            <div style={{ textAlign: "left" }}>
              <p style={{ margin: "2rem 0 0" }}>
                <img style={{ height: "33px" }} src="/rchair.ico" />
              </p>
              <p
                style={{
                  display: "inline-block",
                  borderTop: "2px solid #657897",
                  width: "auto",
                }}
              >
                The Boss Brief Team{" "}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmationQ;
