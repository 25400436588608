import React from "react";

const MissionIcon = ({ style, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    style={style}
    className={className}
  >
    <style type="text/css">
      {`.cls-1{fill:currentColor;stroke-width:0px}`}
    </style>
    <path
      className="cls-1"
      d="m59.54,49.57l-5.83-11.18s0,0,0,0l-.78-1.49-3.83-7.35c-.18-.35-.41-.67-.68-.95-.89-.93-2.21-1.38-3.5-1.19-1.3.19-2.4.97-3.02,2.14l-1.17,2.24-2.48,4.75s0,.01,0,.02l-3.2,6.15-3.96-7.6s0,0,0,0l-5.58-10.69c-.21-.41-.47-.78-.79-1.1-.65-.68-1.48-1.13-2.38-1.33v-4.42h9.66c.55,0,1-.45,1-1v-7.04c0-.55-.45-1-1-1h-10.66c-.55,0-1,.45-1,1v12.47c-1.37.29-2.51,1.16-3.17,2.42l-4.41,8.45s0,.01,0,.02l-8.22,15.77c-.35.68-.53,1.41-.53,2.17,0,1.2.48,2.4,1.32,3.28.89.92,2.09,1.42,3.38,1.42h25.28c.17,0,.34-.03.5-.05.21.04.42.05.58.05h20.88c2.24,0,4.06-1.82,4.06-4.06,0-.65-.16-1.28-.46-1.87ZM22.34,10.5h8.66v5.04h-8.66v-5.04Zm19.56,23.37l1.78-3.41c.31-.6.87-1,1.52-1.09.66-.1,1.32.13,1.77.6.14.14.25.31.35.49l2.88,5.52,1.51,2.9c-.73,1.64-1.28,2.48-1.59,2.84-.42-.48-1.06-1.73-1.46-2.52-1.04-2.04-1.86-3.64-3.16-3.64-1.4,0-2.17,1.59-2.84,2.99-.11.23-.25.53-.39.77-.35-.38-.95-1.1-1.95-2.42l1.57-3.02Zm-22.95-8.56c.41-.79,1.14-1.31,2-1.43.18-.03.36-.02.53-.01.08,0,.16.01.24.02.12.02.24.04.36.08.07.02.15.04.22.07.12.05.24.11.36.17.06.03.12.06.17.09.16.11.32.23.46.38.18.19.33.4.45.64l5.36,10.28c-1.05,2.35-1.8,3.41-2.15,3.75-.53-.46-1.41-2.19-1.96-3.26-1.22-2.4-2.19-4.3-3.64-4.3-1.56,0-2.46,1.86-3.25,3.5-.18.37-.41.86-.61,1.2-.41-.42-1.18-1.32-2.65-3.27l4.12-7.9Zm15.03,28.19H8.7c-.95,0-1.58-.44-1.94-.81-.48-.51-.76-1.19-.76-1.89,0-.44.1-.86.31-1.25l7.5-14.38c2.36,3.08,2.97,3.44,3.48,3.55,1.24.26,1.87-1.05,2.6-2.57.33-.69,1.01-2.1,1.4-2.34.49.38,1.37,2.12,1.91,3.18,1.26,2.47,2.26,4.43,3.77,4.43.5,0,1.48,0,3.28-3.6l6.12,11.74c.2.39.31.81.31,1.25,0,1.3-1.03,2.7-2.7,2.7Zm21.96,0h-18.13c.54-.77.86-1.69.86-2.7,0-.76-.18-1.49-.53-2.17l-1.97-3.78,3.13-6c1.85,2.4,2.35,2.68,2.81,2.78,1.15.25,1.73-.95,2.34-2.22.23-.48.68-1.41,1-1.76.4.45,1.03,1.68,1.42,2.45,1.07,2.1,1.91,3.75,3.27,3.75.68,0,1.48-.38,2.71-2.76l4.9,9.39c.16.3.24.62.24.95,0,.99-.79,2.06-2.06,2.06Z"
    />
  </svg>
);

export default MissionIcon;
