export const idea_backgrounds = [
  {
    backgroundImage:
      "repeating-linear-gradient(45deg, rgba(255, 255, 255, 0.21) 0, rgba(255, 255, 255, 0.21) 1.5px, transparent 0, transparent 50%)",
    backgroundSize: "21px 21px",
    backgroundColor: "#00217c",
    padding: "1rem",
    borderTopLeftRadius: "40px",
    borderTopRightRadius: "40px",
    marginTop: "-2.5rem",
    position: "relative",
    color: "#ffffff",
  },

  {
    background: `radial-gradient(35.36% 35.36% at 100% 25%,#0000 66%,#380071 68% 70%,#0000 72%) 59px 59px/calc(2*59px) calc(2*59px), radial-gradient(35.36% 35.36% at 0    75%,#0000 66%,#380071 68% 70%,#0000 72%) 59px 59px/calc(2*59px) calc(2*59px), radial-gradient(35.36% 35.36% at 100% 25%,#0000 66%,#380071 68% 70%,#0000 72%) 0 0/calc(2*59px) calc(2*59px), radial-gradient(35.36% 35.36% at 0    75%,#0000 66%,#380071 68% 70%,#0000 72%) 0 0/calc(2*59px) calc(2*59px), repeating-conic-gradient(#10005a 0 25%,#0000 0 50%) 0 0/calc(2*59px) calc(2*59px), radial-gradient(#0000 66%,#380071 68% 70%,#0000 72%) 0 calc(59px/2)/59px 59px #10005a`,
    color: "#ffffff",
    padding: "1rem",
    borderTopLeftRadius: "40px",
    borderTopRightRadius: "40px",
    marginTop: "-2.5rem",
    position: "relative",
  },
  {
    background: `conic-gradient(from 135deg,#1c1c1c 90deg,#0000 0) 59px calc(59px/2), conic-gradient(from 135deg,#000000 90deg,#0000 0), conic-gradient(from 135deg at 50% 0,#1c1c1c 90deg,#0000 0) #000000`,
    backgroundSize: "118px 59px",
    padding: "1rem",
    borderTopLeftRadius: "40px",
    borderTopRightRadius: "40px",
    marginTop: "-2.5rem",
    position: "relative",

    color: "#ffffff",
  },
  {
    background: `conic-gradient(from 116.56deg at calc(100%/3) 0, #0000 90deg,#edf7ff 0), conic-gradient(from -63.44deg at calc(200%/3) 100%, #0000 90deg,#edf7ff 0) #ffffff`,
    backgroundSize: "59px 59px",
    padding: "1rem",
    borderTopLeftRadius: "40px",
    borderTopRightRadius: "40px",
    marginTop: "-2.5rem",
    position: "relative",
  },
];

export const skillDescriptions = [
  {
    threshold: 90,
    phrases: [
      "You're totally ready for this one!",
      "Looking solid! Pretty much good to go here.",
      "Almost a perfect match.",
    ],
  },
  {
    threshold: 75,
    phrases: [
      "Really strong match here.",
      "This is definitely in your wheelhouse, with just a bit more prep needed.",
      "Looking great! You can easily get started with this.",
    ],
  },
  {
    threshold: 60,
    phrases: [
      "You're more than halfway there with the skills!",
      "Got a solid base going - definitely something to build on.",
      "You've got a pretty decent foundation already to get you started.",
    ],
  },
  {
    threshold: 45,
    phrases: [
      "About half the skills lined up - not bad at all!",
      "Getting there! Lots of relevant experience to work with.",
      "Decent progress on the skill front already.",
    ],
  },
  {
    threshold: 30,
    phrases: [
      "Making steady progress with the relevant skills.",
      "Got some good experience to start from here.",
      "Definitely room to grow, but not starting from scratch.",
    ],
  },
  {
    threshold: 15,
    phrases: [
      "Early days with this skill set, but that's okay!",
      "Got the basics down - plenty of room to develop.",
      "Could be an interesting direction to grow into.",
    ],
  },
  {
    threshold: 0,
    phrases: [
      "Bit of a stretch for now, but could be a fun challenge!",
      "Starting fresh here - perfect if you're up for learning.",
      "Definitely ambitious, but hey, everyone starts somewhere!",
    ],
  },
];

export const idea_color_schemes = [
  {
    // Blue theme (matches first background)
    primary: "#00217c",
    secondary: "#06207c",
    accent: "#385099",
    text: "#ffffff",
    boxBackground: "#10215e",
    borderColor: "#5a73be",
    barColor: "linear-gradient(0deg, rgb(38 65 145) 0%, rgb(48, 70, 147) 60%)",
    barBackground: "#fff",
    highlightBackground: "#304693",
    iconColors: {
      base: "#fff",
      strong: "#13b64e",
      weak: "#86d591bd",
      none: "#f48d36",
    },
  },

  {
    // Purple theme (matches third background)
    primary: "#380071",
    secondary: "#10005a",
    accent: "#4a0096",
    text: "#ffffff",
    boxBackground: "#2b0054",
    borderColor: "#6b16c5",
    barBackground: "#fff",
    highlightBackground: "#380071",
    iconColors: {
      base: "#fff",
      strong: "#13b64e",
      weak: "#86d591bd",
      none: "#f48d36",
    },
  },
  {
    // Dark theme (matches fifth background)
    primary: "#1c1c1c",
    secondary: "#000000",
    accent: "#333333",
    text: "#ffffff",
    boxBackground: "#262626",
    borderColor: "#ffffff",
    highlightBackground: "#1c1c1c",
    iconColors: {
      base: "#fff",
      strong: "#13b64e",
      weak: "#86d591bd",
      none: "#f48d36",
    },
  },
  {
    // Light blue theme (matches fourth background)
    primary: "#333333",
    secondary: "#edf7ff",
    accent: "#b3d9ff",
    text: "#000000",
    boxBackground: "#f5faff",
    borderColor: "#333333",
    highlightBackground: "#e5f3ff",
    iconColors: {
      base: "#fff",
      strong: "#13b64e",
      weak: "#86d591bd",
      none: "#f48d36",
    },
  },
];
