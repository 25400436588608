import React from "react";
import "../../../assets/styling/bizreport.css";
import { report_elements } from "./report_elements";
import { bold_text } from "../../../utils/bizreport/boldtext";
const Comic = () => {
  const arrow36 = "https://d2oa23hr7q6t96.cloudfront.net/report/arrow.png";
  return (
    <div className="comic-flow-container">
      <div className="comic-panel blue-panel">
        <div className="checkered-pattern"></div>
        <div className="panel-content">
          <img src={report_elements.comic.panel_one.image} alt="" />
          <p style={{ background: "#d7e3ff" }}>
            {bold_text(report_elements.comic.panel_one.text)}
          </p>
        </div>
      </div>

      <img src={arrow36} alt="arrow" className="flow-arrow" />

      <div className="comic-panel orange-panel">
        <div
          className="checkered-pattern"
          style={{ right: "auto", left: "0" }}
        ></div>
        <div className="panel-content">
          <img src={report_elements.comic.panel_two.image} alt="" />
          <p style={{ background: "#ffefd7" }}>
            {bold_text(report_elements.comic.panel_two.text)}
          </p>
        </div>
      </div>

      <img
        style={{ transform: "rotate(178deg)" }}
        src={arrow36}
        alt="arrow"
        className="flow-arrow"
      />

      <div className="comic-panel teal-panel">
        <div className="checkered-pattern"></div>
        <div className="panel-content">
          <img src={report_elements.comic.panel_three.image} alt="" />
          <p style={{ background: "#e0fbf2" }}>
            {bold_text(report_elements.comic.panel_three.text)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Comic;
