import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const WhatNow = () => {
  return (
    <div className="what-now-container">
      <h2>Where You Go From Here</h2>

      <div className="what-now-progress">
        <div className="what-now-step">
          <div className="what-now-circle completed">
            <CircleRoundedIcon />
          </div>
          <div className="what-now-text">
            <span className="step-label">Step One</span>
            <h3>We've Identified Your Strengths</h3>
          </div>
        </div>

        <div className="what-now-step">
          <div className="what-now-circle completed">
            <CircleRoundedIcon />
          </div>
          <div className="what-now-text">
            <span className="step-label">Step Two</span>
            <h3>We've Found Your Business Match</h3>
          </div>
        </div>

        <div className="what-now-step">
          <div className="what-now-circle inverse">
            <CircleRoundedIcon />
          </div>
          <div className="what-now-text">
            <span className="step-label">Step Three</span>
            <h3>Let's Start Making Money</h3>
          </div>
        </div>
      </div>

      <div className="what-now-help">
        <p>Get help with the last step</p>
        <ArrowDownwardIcon className="what-now-arrow" />
      </div>
    </div>
  );
};

export default WhatNow;
