import React from "react";
import "../../../assets/styling/reportsections.css";

const ReportTitle = ({ title1 = "", title2 = "" }) => {
  const [titleWidth, setTitleWidth] = React.useState(0);
  const titleRef = React.useRef(null);

  React.useEffect(() => {
    if (titleRef.current) {
      setTitleWidth(titleRef.current.offsetWidth);
    }
  }, [title2]);

  return (
    <div className="biz-report-section-title">
      <span className="report-title-1st">{title1}</span>
      <div style={{ position: "relative" }}>
        <span className="report-title-2nd" ref={titleRef}>
          {title2}
        </span>
        <div className="underline" style={{ width: titleWidth }} />
      </div>
    </div>
  );
};

export default ReportTitle;
