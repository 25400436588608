import React, { useState, useEffect, forwardRef } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import InsightsIcon from "@mui/icons-material/Insights";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import ScienceIcon from "@mui/icons-material/Science";
import "../../../assets/styling/cta.css";
import Countdown from "./Countdown";
import axios from "axios";

const CTA = () => {
  const [err, setErr] = useState(false);
  const [tempUserId, setToken] = useState(null);
  const [num_map, setNumMap] = useState(null);
  const [bizAlignment, setBizAlignment] = useState(null);
  const [err_statement, setStatement] = useState(
    "Error... please try again later"
  );
  const [buyLoading, setBuyLoading] = useState(false);
  const price = 1;
  const oldPrice = 12;
  const monthlyPrice = 5;
  useEffect(() => {
    const quizScores = JSON.parse(localStorage.getItem("quizScore"));
    const bizAlignment = JSON.parse(localStorage.getItem("bizAlignment"));
    const token = localStorage.getItem("tempUserId");
    setBizAlignment(bizAlignment);
    setToken(token);
    setNumMap(quizScores);
  }, []);

  const clickBuy = async () => {
    try {
      setBuyLoading(true);

      // Get skillset from bizAlignment
      const skillset = bizAlignment?.[0]?.skillset;
      const res = await axios.post("/api/users/temp-save", {
        tempUserId,
        quizData: num_map,
        skillset,
      });
      fbq("track", "Subscribe");
      //test link
      // window.location.href =
      //   "https://buy.stripe.com/test_4gw14A4iJ0A81u8cMM?client_reference_id=" +
      //   tempUserId;

      // Redirect to Stripe Checkout
      //redirect

      window.location.href =
        "https://buy.stripe.com/14kg34gqNfBqgyk3cf?client_reference_id=" +
        tempUserId;
    } catch (err) {
      console.log("Error initiating payment:");
      setStatement(
        "An error occurred while trying to send you to stripe. Please contact support if this happens again."
      );
      setErr(true);
    } finally {
      setBuyLoading(false);
    }
  };

  const cta_bullets = [
    {
      title: "Title",
      text: "The thing and what it does",
      icon: <CheckCircleIcon />,
    },
    {
      title: "Title",
      text: "The thing and what it does",
      icon: <CheckCircleIcon />,
    },
    {
      title: "Title",
      text: "The thing and what it does",
      icon: <CheckCircleIcon />,
    },
  ];
  return (
    <div className="report-cta-container">
      {/* {Array.from({ length: 10 }, (_, index) => (
        <div
          key={index}
          className={index % 2 === 0 ? "cta-black" : "cta-grey"}
          style={{
            width: "100%",
            height: "50px",
            borderRadius: index === 0 ? "30px 30px 0 0" : "",
          }}
        />
      ))} */}
      <div className="report-cta-content">
        <h2
          style={{
            fontSize: "32px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "700",
            marginBottom: "1.5rem",
            background: "linear-gradient(90deg, #0de000, #91c0ff)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Your Next Million-Dollar Opportunity Awaits
        </h2>
        <p
          style={{
            fontSize: "16px",
            fontFamily: "Nunito, sans-serif",
            fontWeight: "400",
            lineHeight: "1.6",
            marginBottom: "2rem",
          }}
        >
          Stop hesitating and take action today. We uncover the hottest trends
          before they hit mainstream, with clear, actionable steps you can
          execute immediately. Start building your future with confidence!
        </p>

        <p
          style={{
            fontSize: "18px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            marginTop: "3rem",
          }}
        >
          Sign Up Now To Receive Weekly:
        </p>

        <div className="summary-card-cta">
          <InsightsIcon
            sx={{
              fontSize: 50,
              background: "linear-gradient(0deg, #0de000 0%, #91c0ff 60%)",
              borderRadius: "50%",
              padding: "6px",
              color: "white",
            }}
            className="summary-card-icon"
          />
          <div className="summary-card-content">
            <h3
              className="summary-card-title"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              An Emerging Trend
            </h3>
            <p
              className="summary-card-description"
              style={{
                fontFamily: "Nunito, sans-serif",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Learn about it before anyone else does.
            </p>
          </div>
        </div>
        <div className="summary-card-cta">
          <FlashOnIcon
            sx={{
              fontSize: 50,
              background: "linear-gradient(0deg, #0de000 0%, #91c0ff 60%)",
              borderRadius: "50%",
              padding: "6px",
              color: "white",
            }}
            className="summary-card-icon"
          />
          <div className="summary-card-content">
            <h3
              className="summary-card-title"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Personalized Strategies
            </h3>
            <p
              className="summary-card-description"
              style={{
                fontFamily: "Nunito, sans-serif",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Tailored to your skills for faster success.
            </p>
          </div>
        </div>

        <div className="summary-card-cta">
          <ScienceIcon
            sx={{
              fontSize: 50,
              background: "linear-gradient(0deg, #0de000 0%, #91c0ff 60%)",
              borderRadius: "50%",
              padding: "6px",
              color: "white",
            }}
            className="summary-card-icon"
          />
          <div className="summary-card-content">
            <h3
              className="summary-card-title"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Launch Plans
            </h3>
            <p
              className="summary-card-description"
              style={{
                fontFamily: "Nunito, sans-serif",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Step-by-step guides to help you see results over the weekend.
            </p>
          </div>
        </div>
        <div className="cta-checklist-container">
          <div className="cta-checklist-item">
            <CheckRoundedIcon sx={{ color: "#0de000" }} />
            <p className="cta-checklist-text">
              Just $<strong>{price}</strong> for your first month, then only{" "}
              {/* <del
                style={{ textDecoration: "line-through", opacity: 0.5 }}
                aria-label={`Original price: $${oldPrice}`}
              >
                ${oldPrice}
              </del>{" "} */}
              $<strong>{monthlyPrice}/month</strong>
            </p>
          </div>
          <div className="cta-checklist-item">
            <CheckRoundedIcon sx={{ color: "#0de000" }} />
            <p className="cta-checklist-text">No commitment-cancel anytime</p>
          </div>
        </div>
        <button
          className="report-cta-btn"
          onClick={clickBuy}
          disabled={buyLoading}
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "18px",
            fontWeight: "700",
            textTransform: "uppercase",
          }}
        >
          {buyLoading ? "Processing..." : `Start Now for $${price}`}
        </button>
        {err && <span className="err-msg">{err_statement}</span>}
        {/* <Countdown /> */}
      </div>
    </div>
  );
};

export default CTA;
