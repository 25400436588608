export const trimDate = (date) => {
  const timeString = new Date(date).toLocaleDateString("en-US");

  return timeString;
};

export const pathsToCheck = [
  "/leadership-guide",
  "/online-business-for-me",
  "/find-your-business-fit",
  "/",
];
export const color_scheme = {
  main_blueish_purple: "#6082d4",
  lighter_version: "#7c9ce6",
  footer_purple: "#606e9f",
  quiz_orange: "#d49960",
  green: "#3ebe67",
  blue_gradient:
    "linear-gradient(128deg,rgb(148, 175, 242),rgb(73 115 205),rgb(142 200 217))",
};
export const setPreloadedImage = (url) => {
  const img = new Image();
  img.src = url;
  // console.log("img loaded");
};

const getPrioritizedImageUrls = (reportElements) => {
  // Priority 1: Opening and first visible sections
  const highPriority = [
    reportElements.open_image,
    reportElements.comic?.panel_one?.image,
    reportElements.comic?.panel_two?.image,
    reportElements.comic?.panel_three?.image,
  ].filter(Boolean);

  // Priority 2: History section images
  const mediumPriority = Object.values(reportElements.history || {})
    .map((item) => item?.image)
    .filter(Boolean);

  // Priority 3: Everything else
  const lowPriority = [
    ...(reportElements.present?.search?.graph
      ? [reportElements.present.search.graph]
      : []),
    ...(reportElements.present?.meta_trend?.image
      ? [reportElements.present.meta_trend.image]
      : []),
    ...(reportElements.case?.company_logo
      ? [reportElements.case.company_logo]
      : []),
    ...Object.values(reportElements.ideas || {})
      .flatMap((idea) => [
        idea?.image,
        ...(idea?.niches?.map((niche) => niche.image) || []),
      ])
      .filter(Boolean),
  ];

  return {
    highPriority,
    mediumPriority,
    lowPriority,
  };
};

export const preloadReportImages = (reportElements) => {
  const { highPriority, mediumPriority, lowPriority } =
    getPrioritizedImageUrls(reportElements);

  // Immediately load high priority images
  highPriority.forEach((url) => setPreloadedImage(url));

  // Load medium priority images after a short delay
  const mediumTimer = setTimeout(() => {
    mediumPriority.forEach((url) => setPreloadedImage(url));
  }, 1000);

  // Load low priority images after user has had time to start reading
  const lowTimer = setTimeout(() => {
    lowPriority.forEach((url) => setPreloadedImage(url));
  }, 3000);

  return () => {
    clearTimeout(mediumTimer);
    clearTimeout(lowTimer);
  };
};
