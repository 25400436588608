import React from "react";
import Loader from "../components/Loader";
import "../assets/styling/about.css";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="about-page-section">
      <div className="about-page-pic-cover"></div>
      <div>
        <img className="about-page-pic" src="images/aboutusoffice.jpg" />
        <div className="about-page-story">
          <p className="about-page-title">Hey there future entrepreneurs!</p>
          <p className="about-page-main">
            After years of building small businesses while juggling a corporate
            career, we understand how challenging it can be to find your path to
            entrepreneurship when you're already busy with a full-time job.
            That's why we created this personalized business trend service. Each
            week, you'll receive carefully curated insights and opportunities
            that match your skills, interests, and available time. Our focus is
            on helping you discover low-investment business opportunities that
            you can start quickly and grow steadily.
          </p>
        </div>
      </div>
      <hr className="about-page-divider" />
      <div className="about-page-intro">
        <p style={{ marginTop: "1em" }}>
          Ready to discover your perfect side business? Take the first step and
          <Link
            className="about-page-signup"
            to="/find-your-business-fit?start=1"
          >
            <strong> find your business fit!</strong>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default About;
