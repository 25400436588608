import React, { useEffect, useState } from "react";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import "../../../assets/styling/legend.css";

const MiniLegend = ({ matches = [], colors }) => {
  const bars = 10;
  const [biz_matches, setBizMatches] = useState([
    { name: "Match 1", percentage_match: 10 },
  ]);

  useEffect(() => {
    if (matches) {
      setBizMatches(matches);
    }
  }, [matches]);

  // Default gradient if no colors provided
  const defaultGradient = "linear-gradient(0deg, #0de000 0%, #91c0ff 60%)";
  const gradientStyle = colors
    ? `linear-gradient(0deg, ${colors.accent} 0%, ${colors.secondary} 60%)`
    : defaultGradient;

  return (
    <div className="legends-container">
      {biz_matches.map((match, index) => (
        <div key={index}>
          <div key={index} className="legend-wrapper">
            <FlashOnIcon
              sx={{
                fontSize: 50,
                background: gradientStyle,
                borderRadius: "50%",
                padding: "6px",
                color: "white",
                zIndex: 1000,
              }}
              className="legend-icon"
            />
            <div className="legend-bars-container">
              {Array(bars)
                .fill()
                .map((_, i) => {
                  const coloredBars = Math.round(
                    (match.percentage_match / 100) * bars
                  );
                  return (
                    <div
                      key={i}
                      className={`legend-bar ${
                        i < coloredBars ? "colored" : "grey"
                      }`}
                      style={{
                        ...(i < coloredBars && colors
                          ? { background: gradientStyle }
                          : {}),
                        ...(i === bars - 1
                          ? {
                              borderTopRightRadius: "8px",
                              borderBottomRightRadius: "8px",
                            }
                          : {}),
                      }}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MiniLegend;
