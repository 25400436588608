import React from "react";

const WinIcon = ({ style, className }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 128 128"
    style={style}
    className={className}
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`.st0{fill:currentColor} .st1{fill:currentColor}`}
    </style>
    <g id="Award"></g>
    <g id="Achievement">
      <g>
        <path
          className="st0"
          d="M85.4,32.7c0.3-1.1,0.6-2.2,0.8-3.3l0.4-2l-3.9-0.8l-0.4,2c-0.2,1-0.5,2-0.8,3L81,33.5l3.8,1.1L85.4,32.7z"
        />
        <path
          className="st0"
          d="M82.9,52.9c17.8-6.4,29.8-24.3,30-44.9c0-0.5-0.2-1-0.6-1.4C112,6.2,111.4,6,110.9,6H94.6
					c-0.3-1.5-0.7-3.1-1.2-4.6c-0.3-0.8-1-1.4-1.9-1.4h-55c-0.9,0-1.7,0.6-1.9,1.4c-0.5,1.5-0.9,3-1.2,4.6H17.1c-0.5,0-1,0.2-1.4,0.6
					S15.1,7.5,15.1,8c0.2,20.8,12.4,38.9,30.5,45.1c3.6,4.1,7.7,7.6,12.4,10.5V75h-7c-1.1,0-2,0.9-2,2v7c-0.1,0-10,1-10,5.5V90h-4
					c-1.1,0-2,0.9-2,2v25c-1.1,0-2,0.9-2,2v7c0,1.1,0.9,2,2,2h63c1.1,0,2-0.9,2-2v-7c0-1.1-0.9-2-2-2h-1V92c0-1.1-0.9-2-2-2h-4v-0.5
					c0-3.6-6.5-4.9-9-5.3V77c0-1.1-0.9-2-2-2h-7V63.5C75.5,60.6,79.5,57,82.9,52.9z M108.9,10c-0.8,15.6-8.9,29.3-21.1,36.3
					c5.3-8.5,8.1-18.5,8.1-28.6c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-2.5-0.2-5-0.5-7.4H108.9z M40.3,46.3C28,39.3,19.9,25.6,19.1,10
					h13.5c-0.3,2.5-0.5,4.9-0.5,7.4c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1C32.2,27.9,35,37.8,40.3,46.3z M94,124H35v-3h58h1V124z M91,117
					H37V94h4h46h4V117z M85,90H43v-0.1c1-0.8,4.8-1.9,6-1.9h2h27h1c1.2,0,5,1.1,6,1.9V90z M76,84H53v-5h23V84z M62,75V62.4
					c0-0.7-0.4-1.4-1-1.7c-15.3-8.8-24.8-25.3-24.8-43.1c0-3.1,0.3-6.2,0.9-9.2c0-0.1,0-0.3,0-0.4c0.3-1.3,0.6-2.7,0.9-4h43.4
					c1.4,4.6,2.1,9.4,2.1,14.2c0,1.2,0,2.4-0.1,3.7l-0.1,2l4,0.3l0.1-2c0.1-1.3,0.1-2.6,0.1-3.9c0-4.8-0.7-9.6-1.9-14.2H90
					c0.4,1.3,0.7,2.6,0.9,4c0,0.1,0,0.3,0,0.4c0.6,3,0.9,6.1,0.9,9.2c0,10.7-3.4,21.1-9.4,29.5c-0.7,1-1.4,1.9-2.2,2.9
					c0,0.1-0.1,0.1-0.1,0.2c-3.4,4.1-7.5,7.7-12.1,10.6c-0.6,0.4-1,1-1,1.7V75H62z"
        />
        <path
          className="st0"
          d="M42,97c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h44c1.1,0,2-0.9,2-2V99c0-1.1-0.9-2-2-2H42z M84,110H44v-9h40V110
					z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M56.2,42.4c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.4-0.9-1.2-0.8-1.9l1.2-7.9l-5.9-5.5c-0.6-0.5-0.8-1.3-0.5-2
					c0.2-0.7,0.8-1.3,1.6-1.4l7.9-1.3l3.4-7.3c0.3-0.7,1-1.1,1.8-1.1c0.7,0,1.5,0.4,1.8,1.1l3.7,7.1l8,1c0.8,0.1,1.4,0.6,1.6,1.3
					c0.2,0.7,0.1,1.5-0.5,2.1l-5.7,5.7l1.5,7.9c0.1,0.7-0.2,1.5-0.8,2c-0.6,0.5-1.4,0.5-2.1,0.2l-7.2-3.6l-7,3.9
					C56.9,42.3,56.5,42.4,56.2,42.4z M55.3,26.5L59,30c0.5,0.5,0.7,1.1,0.6,1.8l-0.8,5l4.4-2.4c0.6-0.3,1.3-0.3,1.9,0l4.5,2.3
					l-0.9-4.9c-0.1-0.6,0.1-1.3,0.5-1.8l3.5-3.6l-5-0.6c-0.7-0.1-1.2-0.5-1.5-1.1L63.9,20l-2.1,4.6c-0.3,0.6-0.8,1-1.5,1.1L55.3,26.5z
					"
        />
        <path
          className="st0"
          d="M119.9,82.2c-0.3,0-0.7-0.1-0.9-0.2l-9.6-5.2L99.8,82c-0.7,0.4-1.5,0.3-2.1-0.1c-0.6-0.4-0.9-1.2-0.8-1.9
						l1.6-10.7l-7.9-7.4c-0.6-0.5-0.8-1.3-0.5-2s0.8-1.3,1.6-1.4l10.7-1.7l4.6-9.8c0.3-0.7,1-1.1,1.8-1.1c0.8,0,1.5,0.4,1.8,1.1l5,9.6
						l10.7,1.4c0.8,0.1,1.4,0.6,1.6,1.3c0.2,0.7,0.1,1.5-0.5,2.1l-7.6,7.7l2,11c0.1,0.8-0.2,1.5-0.8,2
						C120.7,82.1,120.3,82.2,119.9,82.2z M96.3,61.7L102,67c0.5,0.5,0.7,1.1,0.6,1.8l-1.2,7.7l6.9-3.8c0.6-0.3,1.3-0.3,1.9,0l6.9,3.7
						l-1.5-7.9c-0.1-0.6,0.1-1.3,0.5-1.8l5.5-5.6l-7.8-1c-0.7-0.1-1.2-0.5-1.5-1.1l-3.6-6.9l-3.3,7.1c-0.3,0.6-0.8,1-1.5,1.1
						L96.3,61.7z"
        />
        <path
          className="st0"
          d="M29.8,82.5c-0.3,0-0.7-0.1-0.9-0.2L19.3,77l-9.5,5.2c-0.7,0.4-1.5,0.3-2.1-0.1c-0.6-0.4-0.9-1.2-0.8-1.9
							l1.6-10.7l-7.9-7.4c-0.6-0.5-0.8-1.3-0.5-2s0.8-1.3,1.6-1.4l10.7-1.7l4.6-9.8c0.3-0.7,1-1.1,1.8-1.1c0.8,0,1.5,0.4,1.8,1.1l5,9.6
							L36.2,58c0.8,0.1,1.4,0.6,1.6,1.3c0.2,0.7,0.1,1.5-0.5,2.1l-7.6,7.7l2,11c0.1,0.8-0.2,1.5-0.8,2C30.7,82.4,30.3,82.5,29.8,82.5z
							 M6.3,61.9l5.7,5.4c0.5,0.5,0.7,1.1,0.6,1.8l-1.2,7.7l6.9-3.8c0.6-0.3,1.3-0.3,1.9,0l6.9,3.7l-1.5-7.9c-0.1-0.6,0.1-1.3,0.5-1.8
							l5.5-5.6l-7.8-1c-0.7-0.1-1.2-0.5-1.5-1.1l-3.6-6.9l-3.3,7.1c-0.3,0.6-0.8,1-1.5,1.1L6.3,61.9z"
        />
      </g>
    </g>
  </svg>
);

export default WinIcon;
