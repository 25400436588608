import React from "react";

const InvestmentIcon = ({ style, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    style={style}
    className={className}
  >
    <style type="text/css">
      {`.cls-1{fill:currentColor;stroke-width:0px}`}
    </style>
    <path
      className="cls-1"
      d="m33.37,16.95v-.6c.87-.47,1.48-1.38,1.48-2.44,0-1.53-1.25-2.78-2.78-2.78-.03,0-.05.01-.07.01-.03,0-.05-.01-.07-.01-.43,0-.78-.35-.78-.78s.35-.78.78-.78h1.92c.55,0,1-.45,1-1s-.45-1-1-1h-.48v-.26c0-.55-.45-1-1-1s-1,.45-1,1v.31c-1.27.26-2.22,1.38-2.22,2.72,0,1.53,1.25,2.78,2.78,2.78.03,0,.05-.01.07-.01.03,0,.05.01.07.01.43,0,.78.35.78.78s-.35.78-.78.78h-1.92c-.55,0-1,.45-1,1s.45,1,1,1h1.22v.26c0,.55.45,1,1,1s1-.45,1-1Z"
    />
    <path
      className="cls-1"
      d="m42.17,37.81h-7.05l-.29-5.2c3.01-.18,5.37-1.17,6.99-2.97,2.74-3.04,2.33-7.21,2.31-7.38-.05-.47-.42-.84-.89-.89-.17-.02-4.34-.42-7.38,2.31-.56.5-1.03,1.08-1.43,1.72l-.3-5.41c3.46-.94,6.01-4.09,6.01-7.85,0-4.49-3.65-8.14-8.14-8.14s-8.14,3.65-8.14,8.14c0,3.75,2.55,6.91,6.01,7.85l-.3,5.41c-.4-.64-.87-1.21-1.43-1.72-3.04-2.74-7.21-2.33-7.38-2.31-.47.05-.84.42-.89.89-.02.18-.42,4.34,2.31,7.38,1.62,1.8,3.98,2.8,6.99,2.97l-.29,5.2h-7.05c-1.47,0-2.67,1.2-2.67,2.67v1.31c0,1.47,1.2,2.67,2.67,2.67h.11l1.23,12.82c.15,1.56,1.51,2.73,3.16,2.73h11.32c1.65,0,3.01-1.17,3.16-2.73l1.23-12.82h.11c1.47,0,2.67-1.2,2.67-2.67v-1.31c0-1.47-1.2-2.67-2.67-2.67Zm-4.99-12.63c1.63-1.48,3.78-1.78,4.97-1.83-.05,1.19-.35,3.33-1.83,4.97-1.23,1.36-3.05,2.13-5.43,2.3.17-2.38.94-4.2,2.3-5.43Zm-13.5,3.14c-1.48-1.63-1.79-3.79-1.83-4.97,1.18.04,3.31.34,4.96,1.82,1.37,1.23,2.14,3.06,2.31,5.44-2.38-.17-4.21-.94-5.43-2.3Zm2.18-16.18c0-3.38,2.75-6.14,6.14-6.14s6.14,2.75,6.14,6.14-2.75,6.13-6.13,6.14h-.02c-3.38,0-6.13-2.76-6.13-6.14Zm7.25,25.67h-2.22l.97-17.53h.13s0,0,0,0,0,0,0,0h.13l.97,17.53Zm5.72,19.27c-.05.52-.56.92-1.17.92h-11.32c-.61,0-1.12-.4-1.17-.92l-1.21-12.63h16.09l-1.21,12.63Zm4-15.3c0,.37-.3.67-.67.67h-20.33c-.37,0-.67-.3-.67-.67v-1.31c0-.37.3-.67.67-.67h20.33c.37,0,.67.3.67.67v1.31Z"
    />
  </svg>
);

export default InvestmentIcon;
