import InsightsIcon from "@mui/icons-material/Insights";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import ScienceIcon from "@mui/icons-material/Science";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import React, { useEffect, useState } from "react";

const Dashboard = ({ matches = [] }) => {
  const [biz_matches, setBizMatches] = useState([
    { name: "Match 1", percentage_match: 10 },
  ]);
  useEffect(() => {
    if (matches) {
      setBizMatches(matches);
    }
  }, [matches]);

  const matchWord = (percentage) => {
    if (percentage < 60)
      return { a: "have a", b: "Promising Foundation", c: "for" };
    if (percentage <= 69) return { a: "show", b: "Good Alignment", c: "with" };
    if (percentage <= 79) return { a: "are a", b: "Strong Match", c: "for" };
    if (percentage <= 89)
      return { a: "are an", b: "Excellent Match", c: "for" };
    if (percentage >= 90)
      return { a: "are", b: "Practically Perfect", c: "for" };
    return { a: "are a", b: "Match", c: "for" };
  };

  const matchWording = () => {
    if (biz_matches.length === 0) return "";
    if (biz_matches.length === 1) {
      const match1 = matchWord(biz_matches[0].percentage_match);
      return (
        <>
          Based on your answers to the quiz your skills {match1.a}{" "}
          <span className="dash-align-score">{match1.b}</span> {match1.c} the
          first business idea below.
        </>
      );
    }

    if (biz_matches.length === 2) {
      const match1 = matchWord(biz_matches[0].percentage_match);
      const match2 = matchWord(biz_matches[1].percentage_match);

      if (match1.b === match2.b) {
        return (
          <>
            Based on your answers to the quiz your skills {match1.a}{" "}
            <span className="dash-align-score">{match1.b}</span> {match1.c} both
            business ideas below.
          </>
        );
      }
      return (
        <>
          Based on your answers to the quiz your skills {match1.a}{" "}
          <span className="dash-align-score">{match1.b}</span> {match1.c} the
          first business idea below.
        </>
      );
    }

    return "";
  };
  return (
    <div className="dashboard-summary">
      <div className="summary-card">
        <InsightsIcon
          sx={{
            fontSize: 56,
            background: "linear-gradient(135deg, #6082D4 0%, #4C6BAF 100%)",
            borderRadius: "14px",
            padding: "12px",
            color: "#FAFAFA",
            boxShadow: "0 4px 12px rgba(96, 130, 212, 0.3)",
          }}
          className="summary-card-icon"
        />
        <div className="summary-card-content">
          <h3 className="summary-card-title">An Emerging Trend</h3>
          <p className="summary-card-description">
            That you can learn about before everyone else.
          </p>
        </div>
      </div>

      <div className="summary-card">
        <TuneRoundedIcon
          sx={{
            fontSize: 56,
            background: "linear-gradient(135deg, #6082D4 0%, #4C6BAF 100%)",
            borderRadius: "14px",
            padding: "12px",
            color: "#FAFAFA",
            boxShadow: "0 4px 12px rgba(96, 130, 212, 0.3)",
          }}
          className="summary-card-icon"
        />
        <div className="summary-card-content">
          <h3 className="summary-card-title">How Your Skillset</h3>
          <p className="summary-card-description">
            Perfectly positions you to profit from it.
          </p>
        </div>
      </div>
      <div className="summary-card">
        <FlashOnIcon
          sx={{
            fontSize: 56,
            background: "linear-gradient(135deg, #6082D4 0%, #4C6BAF 100%)",
            borderRadius: "14px",
            padding: "12px",
            color: "#FAFAFA",
            boxShadow: "0 4px 12px rgba(96, 130, 212, 0.3)",
          }}
          className="summary-card-icon"
        />
        <div className="summary-card-content">
          <h3 className="summary-card-title">Business Ideas</h3>
          <p className="summary-card-description">
            You can put into action today to profit from this trend.
          </p>
        </div>
      </div>
      {biz_matches.length > 0 && (
        <div className="summary-card">
          <div className="summary-card-content">
            <p className="summary-card-description">{matchWording()}</p>
          </div>
        </div>
      )}

      {/* <div className="summary-card">
        <ScienceIcon
          sx={{
            fontSize: 56,
            background: "linear-gradient(135deg, #6082D4 0%, #4C6BAF 100%)",
            borderRadius: "14px",
            padding: "12px",
            color: "#FAFAFA",
            boxShadow: "0 4px 12px rgba(96, 130, 212, 0.3)",
          }}
          className="summary-card-icon"
        />
        <div className="summary-card-content">
          <h3 className="summary-card-title">Easy Test</h3>
          <p className="summary-card-description">
            To try this weekend and see if it works
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
