import React from "react";
import { report_elements } from "./report_elements";
import { bold_text } from "../../../utils/bizreport/boldtext";
const SwipeRevealBox = ({ text = "intro" }) => {
  const swipeText = {
    cta: report_elements.transition_three,
    intro: report_elements.transition_one,
    idea: report_elements.transition_two,
    history: report_elements.transition_two,
  };
  return (
    <div className="swipe-reveal-container">
      {/* The box that swipes left-to-right */}
      <div className="swipe-box"></div>

      {/* Text that is initially covered by the box */}
      <div className="hidden-text">
        <div className="hidden-text-container">
          <p style={{ fontSize: "1.5rem" }} className="hidden-text-title">
            {swipeText[text].title}
          </p>
          <p className="hidden-text-text"> {bold_text(swipeText[text].text)}</p>
        </div>
      </div>
    </div>
  );
};

export default SwipeRevealBox;
