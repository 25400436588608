import React, { useEffect, useState } from "react";
import MiniLegend from "./MiniLegend";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import LaptopTwoToneIcon from "@mui/icons-material/LaptopTwoTone";
import PaletteTwoToneIcon from "@mui/icons-material/PaletteTwoTone";
import VideoLibraryTwoToneIcon from "@mui/icons-material/VideoLibraryTwoTone";
import RecordVoiceOverTwoToneIcon from "@mui/icons-material/RecordVoiceOverTwoTone";
import HeadsetMicTwoToneIcon from "@mui/icons-material/HeadsetMicTwoTone";
import VideocamTwoToneIcon from "@mui/icons-material/VideocamTwoTone";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone";
import FlareRoundedIcon from "@mui/icons-material/FlareRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import "../../../assets/styling/bizreport-idea.css";
import { idea_color_schemes } from "../../../utils/bizreport/reportutils";
import { report_elements } from "./report_elements";

const icon_size = 24; // You can adjust this value as needed

const icons = {
  tech: {
    icon: <LaptopTwoToneIcon sx={{ fontSize: icon_size, color: "inherit" }} />,
    name: "Managing The Tech",
    description: {
      low: "You'll likely need significant help with the technical setup. Not impossible, but be ready to bring in extra support.",
      med: "You might need a little extra help getting the tech in place. Most should be doable with a bit of guidance.",
      high: "Your tech skills should be fine here. You'll likely handle the setup and any issues easily.",
    },
  },
  design: {
    icon: <PaletteTwoToneIcon sx={{ fontSize: icon_size, color: "inherit" }} />,
    name: "Designing The Product",
    description: {
      low: "This requires quite a bit of design work. Consider hiring or collaborating if it's not your strong suit.",
      med: "A little extra time with design tools, and you'll manage. Practice plus a good eye goes a long way.",
      high: "Your design skillset is a perfect fit. You'll shape the product's look with ease.",
    },
  },
  content: {
    icon: (
      <VideoLibraryTwoToneIcon sx={{ fontSize: icon_size, color: "inherit" }} />
    ),
    name: "Creating Content",
    description: {
      low: "Not keen on creating content? Partner with someone who is, so you can still benefit without the heavy workload.",
      med: "You might need to produce more content than usual. Consistency matters—find your rhythm, and you'll see results.",
      high: "Since you're great at creating content, just keep delivering value, and you'll thrive.",
    },
  },
  personal_brand: {
    icon: (
      <RecordVoiceOverTwoToneIcon
        sx={{ fontSize: icon_size, color: "inherit" }}
      />
    ),
    name: "Building The Personal Brand",
    description: {
      low: "Putting your face out there helps. If self-promotion feels awkward, start small with authentic personal stories.",
      med: "Tune up your self-promo a bit, and you'll be fine. Share experiences to build trust.",
      high: "You'll easily build rapport and credibility with your audience since you're well versed in personal branding. ",
    },
  },
  support: {
    icon: (
      <HeadsetMicTwoToneIcon sx={{ fontSize: icon_size, color: "inherit" }} />
    ),
    name: "Providing Support",
    description: {
      low: "Customer support is crucial. If it's not your strong point, consider outsourcing or a working with a support person.",
      med: "Be ready to handle most inquiries yourself. Organization and timely responses make all the difference.",
      high: "Your support skills and dedication to helping users will be your key to success.",
    },
  },
  coaching: {
    icon: <SchoolTwoToneIcon sx={{ fontSize: icon_size, color: "inherit" }} />,
    name: "Guiding and Mentoring",
    description: {
      low: "Not confident coaching yet? Offer basic guidance or partner with someone who is. Training/partnerships can fill the gap.",
      med: "You've got the basics down. With some polish, coaching could become a standout part of your service.",
      high: "Leverage your talent for coaching to differentiate your brand and deliver major value.",
    },
  },
  camera: {
    icon: (
      <VideocamTwoToneIcon sx={{ fontSize: icon_size, color: "inherit" }} />
    ),
    name: "Being On Camera",
    description: {
      low: "If you dont like being on camera - focus on written or audio first. When you are ready to go on camera, practice and feedback will help.",
      med: "With practice and small tweaks, your on camera presence will appear more authentic and confident.",
      high: "Since you're a natural on camera, use that superpower to engage viewers and build trust.",
    },
  },
  community: {
    icon: <GroupsTwoToneIcon sx={{ fontSize: icon_size, color: "inherit" }} />,
    name: "Building A Community",
    description: {
      low: "If you're not the best at it, start small with a simple group or channel. Lean on motivated members or partners to help build momentum.",
      med: "You have the skills to grow a decent community. Plan engagement strategies like discussions or events to keep people involved.",
      high: "Use your strengths in uniting people to create a vibrant, supportive community that keeps them around.",
    },
  },
};

const Idea = ({ matches = {}, styling, index }) => {
  const [biz_matches, setBizMatches] = useState([
    {
      name: "",
      skills_needed: [""],
      id: "",
      percentage_match: 60,
      matching_skills: [
        {
          name: "",
          score: 1,
        },
      ],
      advice: "",
    },
  ]);
  const [idea, setIdea] = useState({
    image: "",
    name: "",
    what_it_is: "",
    one_liner: "",
    reasons: [],
    niches: [],
    launch_plan_label: "this idea",
  });

  useEffect(() => {
    if (matches && matches.id && report_elements.ideas[matches.id]) {
      setBizMatches([matches]);
      setIdea(report_elements.ideas[matches.id]);
    } else {
      console.warn("Missing or invalid matches data:", matches);
    }
  }, [matches]);

  const skillLevel = (skill) => {
    const match = biz_matches[0].matching_skills.find((m) => m.name === skill);
    const name = icons[skill.toLowerCase()]?.name || skill;
    if (match) {
      return match.score === 1 ? (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
            <CheckCircleOutlineRoundedIcon
              sx={{ color: icon_colors.strong, fontSize: "1.5rem" }}
            />
            <p style={{ fontWeight: "900" }}>{name}</p>
          </div>
          <p>{icons[skill.toLowerCase()]?.description.med || skill}</p>
        </>
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
            <VerifiedRoundedIcon
              sx={{ color: icon_colors.strong, fontSize: "2rem" }}
            />
            <p style={{ fontWeight: "900" }}>{name}</p>
          </div>

          <p>{icons[skill.toLowerCase()]?.description.high || skill}</p>
        </>
      );
    }
    return (
      <>
        <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
          <WarningRoundedIcon sx={{ color: icon_colors.none }} />
          <p style={{ fontWeight: "900" }}>{name}</p>
        </div>
        <p>{icons[skill.toLowerCase()]?.description.low || skill}</p>
      </>
    );
  };
  // Get the matching color scheme
  const colors = idea_color_schemes[index];

  // Update the icon_colors constant to use the scheme
  const icon_colors = colors.iconColors;

  return (
    <div
      className="idea-container"
      style={{
        textAlign: "center",
      }}
    >
      <img
        style={{
          width: "100%",

          objectFit: "cover",
        }}
        src={idea.image}
        alt={`Idea ${index + 1}`}
      />
      <div
        style={{
          ...styling,
          color: colors.text,
          "--accent": colors.accent,
          "--secondary": colors.secondary,
          "--borderColor": colors.borderColor,
          "--boxBackground": colors.boxBackground,
          "--text": colors.text,
          "--barBackground": colors.barBackground,
        }}
      >
        <h2 className="idea-title">{idea.name}</h2>

        {/* What is it */}
        <p className="idea-oneliner">{idea.what_it_is}</p>
        {/* Why it works */}

        <div className="why-it-works">
          <p className="idea-subtitle">Why it works</p>
          <div className="why-it-works-list">
            {idea.reasons.map((why, index) => (
              <p className="why-it-works-item" key={index}>
                {why}
              </p>
            ))}
          </div>
        </div>
        {/* Possible Slogan */}
        <div className="idea-slogan">
          <p
            className="idea-subtitle"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "3px",
            }}
          >
            <StarRoundedIcon
              sx={{
                color: idea_color_schemes[index].borderColor,
                fontSize: "1.5rem",
              }}
            />
            <span>Potential Slogan</span>
            <StarRoundedIcon
              sx={{
                color: idea_color_schemes[index].borderColor,
                fontSize: "1.5rem",
              }}
            />
          </p>
          <p className="idea-oneliner">{idea.one_liner}</p>
        </div>
        {/* Possiblities */}
        <div className="idea-possibilities">
          <p className="idea-subtitle">Niches To Explore</p>
          {idea.niches.map((niche, index) => (
            <div className="idea-possibilities-item" key={index}>
              <img src={niche.image} alt={niche.name} />
              <div className="idea-possibilities-item-description">
                <p className="idea-possibilities-item-title">{niche.name}</p>
                <p className="niche-description">{niche.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Skill Breakdown */}
        <div
          className="idea-skill-breakdown"
          style={{ marginTop: "2rem", textAlign: "left" }}
        >
          <p
            className="idea-subtitle idea-slide-in-left"
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              letterSpacing: "-1px",
              marginTop: "4rem",
            }}
          >
            How Your Current Skills Match The Needs of This Idea
          </p>

          <div className="skill-breakdown-header idea-slide-in-right">
            <MiniLegend matches={biz_matches} colors={colors} />
          </div>

          {biz_matches[0].skills_needed.map((skill, index) => (
            <div className={`idea-skill-breakdown-item`} key={index}>
              <div className="skill-breakdown-item-container">
                <span className="skill-breakdown-item-icon">
                  {icons[skill.toLowerCase()]?.icon || "🔧"}
                </span>
                <div className="skill-breakdown-item-details">
                  {skillLevel(skill)}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="idea-launch-plan" style={{ marginTop: "3rem" }}>
          <p className="idea-subtitle">Your Launch Plan</p>
          <div
            style={{
              width: "100px",
              height: "100px",
              margin: "auto",
              borderRadius: "50%",
              background: "var(--accent)",
              display: "flex",
              alignItems: "flex-end",
              overflow: "hidden",
              justifyContent: "flex-end",
            }}
          >
            <img
              style={{ width: "80%" }}
              src="https://d2oa23hr7q6t96.cloudfront.net/report/freelaunchplan.png"
              alt="Launch Plan"
            />
          </div>

          <div
            style={{
              background: "var(--accent)",
              padding: "1rem 10px",
              borderRadius: "10px",
              margin: "1rem",
              fontFamily: "Nunito",
            }}
          >
            Like this idea? Here's a quick{" "}
            <a
              href="#launch-plan"
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById("launch-plan")
                  .scrollIntoView({ behavior: "smooth" });
              }}
              style={{
                color: "white",
                textDecoration: "underline",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              launch plan
            </a>{" "}
            to help you test {idea.launch_plan_label} over the weekend.
            <br />
            <br />A few hours is all you'll need to get started!
          </div>
        </div>
      </div>
    </div>
  );
};

export default Idea;
