import React, { useState, useEffect, useRef } from "react";
import BarGraph from "./BarGraphs";
import { shuffleColors, correctOpacity } from "../../utils/graphhelper";
import ReportCTA from "./ReportCTA";
import WhatNow from "./WhatNow";
import { campaignImg } from "../../utils/quiz_page_rotation";
import { report_elements } from "../BizReport/ReportComponents/report_elements";
import { preloadReportImages } from "../../utils/helpful";

const BizQuizResults = ({
  map,
  results,
  version,
  campaign,
  num_map,
  custom_cta,
  token,
}) => {
  const [campaign_img, setCampaign] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    setCampaign(campaignImg(campaign));

    // Preload images and get cleanup function
    const cleanup = preloadReportImages(report_elements);

    // Clean up timeouts if component unmounts
    return cleanup;
  }, []);

  const ctaBox = useRef(null);
  const barColorShuffled = shuffleColors();
  // Get the first 3 colors from the shuffled colors
  const firstThreeColors = barColorShuffled.slice(0, 3);

  // Create an object to store 10 variations for each of the first three colors
  const colorVariations = {};

  // Iterate over each of the first three colors
  firstThreeColors.forEach((color, index) => {
    // Create an array to hold the 10 variations of the current color
    const variations = [];

    // Generate 10 variations by adjusting the opacity
    for (let i = 0; i < 10; i++) {
      const variation = correctOpacity(color, i, 10);
      variations.push(variation);
    }

    // Store the variations in the colorVariations object
    colorVariations[index + 1] = variations;
  });
  if (results.topResults && results.topResults.length < 1) return null;
  return (
    <div>
      <div style={{ marginTop: "1rem", textAlign: "center" }}>
        <p className="survey-title">Your Results Are In! 🎉</p>
        <img className="survey-img" src="/images/results4.jpg" />
        <div className="survey-border" />

        <p
          style={{
            textAlign: "left",
            fontSize: "1.06rem",
            background: "white",
          }}
          className="survey-desc"
        >
          <b>Congrats on completing the quiz!</b> We've curated a list of
          businesses just for you, organized by categories to give you a variety
          of options to explore.
        </p>
        <div className="survey-border" />
        <BarGraph
          map={map}
          datasets={results}
          colors={colorVariations}
          bars={barColorShuffled}
          version={version}
          campaign={campaign}
          num_map={num_map}
          handleCTAScroll={() =>
            ctaBox.current.scrollIntoView({ behavior: "smooth" })
          } // Pass handleCTAScroll to BarGraph
        />
      </div>
      <WhatNow />
      <ReportCTA />
      {/* <QuizCTA
        ref={ctaBox}
        results={results}
        version={version}
        campaign={campaign}
        num_map={num_map}
        custom_cta={custom_cta}
        token={token}
      /> */}
    </div>
  );
};

export default BizQuizResults;
