const sharedPLBAI = {
  whole_title: "Find Your Perfect Business Fit!",
  title: "Find Your ",
  span: "Perfect Business",
  title2: " Fit!",
};
const basic_coverage = {
  de: sharedPLBAI,
  co: sharedPLBAI,
  pe: sharedPLBAI,
  sh: sharedPLBAI,
  fd: sharedPLBAI,
};
const basic_bullet_coverage = {
  de: "Uncover the online business that's perfectly tailored to you and learn how to grow it effortlessly. Take our [[free 2-minute quiz]] and get your personalized report today!",
  co: "Uncover the online business that's perfectly tailored to you and learn how to grow it effortlessly. Take our [[free 2-minute quiz]] and get your personalized report today!",
  pe: "Uncover the online business that's perfectly tailored to you and learn how to grow it effortlessly. Take our [[free 2-minute quiz]] and get your personalized report today!",
  sh: "Uncover the online business that's perfectly tailored to you and learn how to grow it effortlessly. Take our [[free 2-minute quiz]] and get your personalized report today!",
  fd: "Uncover the online business that's perfectly tailored to you and learn how to grow it effortlessly. Take our [[free 2-minute quiz]] and get your personalized report today!",
};
//create new shared Bold Title for separate campaign
const sharedExample2 = {
  whole_title: "Find Your Perfect Business Fit!",
  title: "Find Your ",
  span: "Perfect Business",
  title2: " Fit!",
};

export const price = "11";
export const old_price = "29.99";
export const preorder_date = "February 19th";
export const combo = {
  auto: {
    //Hero Section -
    bold_title: basic_coverage,
    //bullets
    sub_bullets: [
      "Discover your top business matches",
      "Get steps to automate and scale each one",
      "Gain an edge over 99% of online business owners",
    ],
    // sub_bullets: [
    //   "Get a free report with your top business matches",
    //   "Automate and scale your business effortlessly",
    //   "Gain an edge over 99% of online business owners",
    // ],
    //value equation
    campaign_bullet: basic_bullet_coverage,
    action1: "Take The Quiz!",
    offer: "Continue",
    titleSection2: "Business Fit Report",
  },
  str: {
    //Hero Section -
    bold_title: basic_coverage,
    //value equation
    campaign_bullet: basic_bullet_coverage,
    action1: "Take The Quiz!",
    offer: "Continue",
    titleSection2: "Business Fit Report",
  },
  //version3

  //version4
};
const sharedAdBold = {
  whole_title: "",
  title: "",
  span: "",
  title2: "",
};

export const page_base = {
  //Hero Section
  bold_title: basic_coverage,
  //doubts
  sub_bullets: [],
  //value equation
  bullets: "",
  //subheader related to campaign
  campaign_bullet: basic_bullet_coverage,
  ad_bullet: "",
  action1: "",
  offer: "",
  titleSection2: "",
};
export const stat_base = [
  {
    stat: "$305B+",
    context: "The expected market size of AI by end of 2024.",
    img: "https://d2oa23hr7q6t96.cloudfront.net/66ccc34991c174e884be5df8/Id/3d07fc14f890e7f1d1e09d2f7ce218ea9368a9f1098ef32df339b765d6340989",
  },
  {
    stat: "64%",
    context: "Businesses planning to use AI to increase productivity.",
    img: "https://d2oa23hr7q6t96.cloudfront.net/66ccc34991c174e884be5df8/Id/3f458669d338c2a88848523ce520a78fcd2c0630f6f125dec8ab88875c119ff9",
  },
  {
    stat: "$15.7 Trillion",
    context: "Predicted AI contribution to the global economy by 2030.",
    img: "https://d2oa23hr7q6t96.cloudfront.net/66ccc34991c174e884be5df8/Id/4b11201f06b73387adb1b8f6ca801dbc2b95f7ea9c6cb0d0fb9f15860b8ecf82",
  },
  {
    stat: "#1",
    context: "Overall current priority for company hiring and training.",
    img: "https://d2oa23hr7q6t96.cloudfront.net/66ccc34991c174e884be5df8/Id/6c9e39247fea7d32fa1167e9b66c4661c34e7ac0b378a26a8fdb480e0c336cc3",
  },
];

export const campaigns = {
  //default
  de: {
    image: "/images/desk.jpg",
    ad_bullet: "",
  },
  //Sign
  co: {
    image: "/images/sign.jpg",
    ad_bullet: "",
  },
  //Personality
  pe: {
    image: "/images/compass.jpg",
    ad_bullet: "",
  },
  //Side
  sh: {
    image: "/images/plants.jpg",
    ad_bullet: "",
  },
  //AI
  fd: {
    image: "/images/train.jpg",
    ad_bullet: "",
  },
};

export const campaignImg = (camp) => {
  return campaigns[camp].image;
};
export const campaignBullet = (camp) => {
  return campaigns[camp].ad_bullet;
};
export const landing = (version) => {
  if (Object.keys(combo).includes(version)) return combo[version];
  return combo["auto"];
};

export const replacePricesInString = (str, replacements) => {
  Object.keys(replacements).forEach(function (key) {
    const placeholder = `[${key}]`; // Placeholder format like [price]
    const value = replacements[key];

    // Split and join to replace all instances of the placeholder
    str = str.split(placeholder).join(value);
  });

  return str;
};
export const calculateDiscount = (oldPriceStr, newPriceStr) => {
  // Function to clean the price string and convert it to a number
  function parsePrice(priceStr) {
    return parseFloat(priceStr.replace(/[^0-9.]/g, ""));
  }

  // Parse the old and new price strings to numbers
  const oldPrice = parsePrice(oldPriceStr);
  const newPrice = parsePrice(newPriceStr);

  // Check if inputs are valid numbers
  if (
    isNaN(oldPrice) ||
    isNaN(newPrice) ||
    oldPrice <= 0 ||
    newPrice < 0 ||
    newPrice > oldPrice
  ) {
    return "Invalid prices";
  }

  // Calculate the discount percentage
  const discount = ((oldPrice - newPrice) / oldPrice) * 100;

  // Round the discount to the nearest multiple of 5
  const roundedDiscount = Math.round(discount / 5) * 5;

  return roundedDiscount;
};

export const checkPriceFormat = (amount) => {
  if (amount.includes(".")) return amount;
  return amount + ".00";
};
