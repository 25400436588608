import React from "react";

const OperationsIcon = ({ style, className }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 256"
    style={style}
    className={className}
  >
    <style type="text/css">{`.st0{fill:currentColor}`}</style>
    <g id="_x38_">
      <path
        className="st0"
        d="M215.596,222.143l-4.937-8.06c-3.321,0.213-6.656,0.005-9.959-0.627l-5.902,7.4
		l-15.239-7.448l2.209-9.203c-2.527-2.216-4.742-4.718-6.614-7.472l-9.397,1.059l-5.511-16.046l8.063-4.937
		c-0.213-3.327-0.003-6.662,0.628-9.959l-7.399-5.902l7.447-15.245l9.205,2.212c2.212-2.523,4.715-4.738,7.468-6.614l-1.055-9.393
		l16.044-5.511l4.937,8.06c3.315-0.215,6.659-0.005,9.959,0.627l5.905-7.399l15.239,7.447l-2.209,9.205
		c2.524,2.216,4.739,4.718,6.614,7.472l9.394-1.059L256,166.794l-8.063,4.937c0.213,3.322,0.003,6.658-0.628,9.959l7.402,5.902
		l-7.453,15.245l-9.202-2.213c-2.212,2.523-4.715,4.739-7.471,6.616l1.061,9.393L215.596,222.143z M213.886,207.568l4.378,7.152
		l6.71-2.303l-0.941-8.333l1.677-1.022c3.363-2.051,6.323-4.67,8.796-7.788l1.22-1.539l8.171,1.965l3.113-6.374l-6.566-5.238
		l0.463-1.905c0.938-3.88,1.187-7.825,0.739-11.729l-0.225-1.949l7.149-4.38l-2.305-6.709l-8.33,0.939l-1.022-1.675
		c-2.049-3.358-4.67-6.318-7.789-8.799l-1.536-1.22l1.962-8.168l-6.374-3.113l-5.238,6.566l-1.905-0.461
		c-3.877-0.941-7.822-1.193-11.729-0.738l-1.95,0.224l-4.378-7.152l-6.71,2.305l0.941,8.332l-1.677,1.022
		c-3.36,2.051-6.32,4.671-8.796,7.789l-1.22,1.536l-8.171-1.962l-3.113,6.374l6.569,5.238l-0.463,1.908
		c-0.938,3.869-1.187,7.815-0.739,11.726l0.225,1.949l-7.149,4.38l2.302,6.709l8.336-0.941l1.022,1.678
		c2.043,3.357,4.664,6.317,7.786,8.796l1.536,1.22l-1.962,8.168l6.374,3.115l5.238-6.568l1.905,0.463
		c3.874,0.938,7.813,1.19,11.726,0.736L213.886,207.568z M208.098,204.27c-4.502,0-8.979-1.026-13.141-3.061
		c-7.194-3.516-12.592-9.624-15.191-17.198l0,0c-2.605-7.574-2.101-15.709,1.415-22.905c3.516-7.194,9.625-12.59,17.198-15.192
		c15.645-5.376,32.726,2.983,38.099,18.617c5.37,15.636-2.981,32.726-18.614,38.098C214.67,203.724,211.377,204.27,208.098,204.27z
		 M208.101,150.44c-2.563,0-5.166,0.416-7.72,1.294c-6.022,2.068-10.876,6.356-13.67,12.075c-2.795,5.719-3.194,12.183-1.127,18.204
		l0,0c2.068,6.019,6.356,10.873,12.075,13.667c5.722,2.796,12.189,3.193,18.205,1.127c12.426-4.269,19.062-17.852,14.797-30.278
		C227.268,156.658,217.997,150.44,208.101,150.44z M208.107,190.796c-2.479,0-4.943-0.565-7.236-1.686
		c-3.967-1.937-6.939-5.303-8.372-9.475c-2.957-8.614,1.644-18.029,10.257-20.988c8.61-2.956,18.031,1.641,20.988,10.255
		c2.957,8.616-1.644,18.031-10.254,20.989C211.728,190.496,209.916,190.796,208.107,190.796z M208.113,163.905
		c-1.115,0-2.248,0.182-3.357,0.563c-5.403,1.856-8.291,7.764-6.434,13.169c0.899,2.617,2.762,4.729,5.25,5.944
		c2.488,1.214,5.298,1.391,7.916,0.49c5.403-1.856,8.291-7.764,6.434-13.169C216.449,166.609,212.417,163.905,208.113,163.905z
		 M102.498,210.646H74.294l-3.806-17.11c-6.963-1.747-13.61-4.509-19.813-8.233l-14.812,9.427l-19.948-19.947l9.427-14.814
		c-3.726-6.209-6.488-12.856-8.233-19.811L0,136.357v-28.205l17.11-3.805c1.747-6.963,4.509-13.61,8.233-19.813l-9.427-14.812
		l19.948-19.948l14.812,9.427c6.209-3.726,12.856-6.488,19.813-8.233l3.806-17.111h28.203l3.801,17.111
		c6.963,1.744,13.61,4.506,19.816,8.233l14.812-9.427l19.945,19.945l-9.427,14.814c3.726,6.209,6.488,12.856,8.231,19.814
		l17.111,3.805v28.205l-17.108,3.803c-1.743,6.957-4.508,13.604-8.234,19.813l9.427,14.812l-19.945,19.947l-14.812-9.427
		c-6.206,3.725-12.853,6.488-19.816,8.233L102.498,210.646z M81.699,201.415h13.394l3.465-15.601l3.714-0.811
		c7.314-1.611,14.268-4.5,20.66-8.586l3.2-2.043l13.502,8.593l9.475-9.474l-8.601-13.513l2.056-3.199
		c4.081-6.384,6.969-13.338,8.58-20.657l0.817-3.707l15.594-3.465v-13.395l-15.594-3.468l-0.817-3.704
		c-1.611-7.325-4.502-14.277-8.586-20.671l-2.049-3.191l8.601-13.511l-9.475-9.472l-13.502,8.593l-3.197-2.04
		c-6.395-4.09-13.349-6.979-20.669-8.59l-3.708-0.81l-3.465-15.603H81.699l-3.471,15.603l-3.71,0.811
		c-7.316,1.611-14.268,4.5-20.663,8.586l-3.191,2.047l-13.508-8.596l-9.475,9.475l8.595,13.504l-2.045,3.197
		c-4.084,6.39-6.973,13.344-8.587,20.668l-0.81,3.704l-15.601,3.469v13.395l15.601,3.466l0.811,3.71
		c1.611,7.316,4.5,14.268,8.586,20.663l2.046,3.191l-8.596,13.51l9.475,9.474l13.508-8.596l3.196,2.048
		c6.386,4.082,13.34,6.972,20.665,8.586l3.704,0.81L81.699,201.415z M88.395,173.927c-28.493,0-51.673-23.182-51.673-51.675
		s23.18-51.673,51.673-51.673c28.495,0,51.676,23.18,51.676,51.673S116.889,173.927,88.395,173.927z M88.395,79.81
		c-23.402,0-42.442,19.039-42.442,42.442s19.039,42.443,42.442,42.443c23.404,0,42.445-19.041,42.445-42.443
		S111.799,79.81,88.395,79.81z M88.395,151.209c-15.968,0-28.957-12.99-28.957-28.957s12.99-28.957,28.957-28.957
		c15.966,0,28.957,12.99,28.957,28.957S104.361,151.209,88.395,151.209z M88.395,102.526c-10.876,0-19.726,8.849-19.726,19.726
		s8.85,19.726,19.726,19.726s19.726-8.849,19.726-19.726S99.27,102.526,88.395,102.526z"
      />
    </g>
  </svg>
);

export default OperationsIcon;
