export const startstop = {
  mobile: {
    //General
    fadeDown: { start: "top 65%", end: "top 35%" },
    fadeUp: { start: "top 65%", end: "top 35%" },
    fadeLeft: { start: "top 65%", end: "top 35%" },
    fadeRight: { start: "top 65%", end: "top 35%" },
    zoom: { start: "top 65%", end: "top 35%" },
    flip: { start: "top 65%", end: "top 35%" },
    blockReveal: { start: "top 65%", end: "top 35%" },
    bgColorChange: { start: "top 65%", end: "top 35%" },
    fade: { start: "top 65%", end: "top 35%" },

    //Box Swipe Reveal
    boxSwipeReveal: { start: "top 65%", end: "top 35%" },

    //Underline
    titleUnderline: { start: "top 65%", end: "top 35%" },

    //Reveal
    titleReveal: { start: "top 65%", end: "top 35%" },

    //Comic
    comicFirstPanel: { start: "top 65%", end: "top 35%" },
    comicNormalPanel: { start: "top 65%", end: "top 35%" },

    //Timeline
    timelineContainers: { start: "top 65%", end: "top 35%" },
    timelineVerticalLine: { start: "top 65%", end: "top 35%" },
    timelineImage: { start: "top 65%", end: "top 35%" },
    timelineContentText: { start: "top 65%", end: "top 35%" },

    //Present
    presentSections: { start: "top 65%", end: "top 35%" },
    presentZoomElements: { start: "top 65%", end: "top 35%" },

    //Case Study
    caseStudySection: { start: "top 65%", end: "top 35%" },
    caseStudyContent: { start: "top 65%", end: "top 35%" },

    //Meaning
    meaningCards: { start: "top 65%", end: "top 35%" },
    meaningVerifiedContainer: { start: "top 65%", end: "top 35%" },

    //Idea Sequence
    ideaContainer: { start: "top 65%", end: "top 35%" },
    ideaContent: { start: "top 65%", end: "top 35%" },

    //CTA
    ctaContainer: { start: "top 65%", end: "top 35%" },
  },
  desktop: {
    //General
    fadeDown: { start: "top 65%", end: "top 35%" },
    fadeLeft: { start: "top 65%", end: "top 35%" },
    fadeRight: { start: "top 65%", end: "top 35%" },
    zoom: { start: "top 65%", end: "top 35%" },
    flip: { start: "top 65%", end: "top 35%" },
    blockReveal: { start: "top 65%", end: "top 35%" },
    bgColorChange: { start: "top 65%", end: "top 35%" },
    fade: { start: "top 65%", end: "top 35%" },

    //Box Swipe Reveal
    boxSwipeReveal: { start: "top 50%", end: "top 35%" },

    //Underline
    titleUnderline: { start: "top 65%", end: "top 45%" },

    //Reveal
    titleReveal: { start: "top 75%", end: "bottom 100%" },

    //Comic
    comicFirstPanel: { start: "top 55%", end: "top 25%" },
    comicNormalPanel: { start: "top 65%", end: "top 35%" },

    //Timeline
    timelineContainers: { start: "top 65%", end: "top 35%" },
    timelineVerticalLine: { start: "top 65%", end: "top 15%" },
    timelineImage: { start: "top 65%", end: "top 15%" },
    timelineContentText: { start: "top 65%", end: "top 15%" },

    //Present
    presentSections: { start: "top 65%", end: "top 35%" },
    presentZoomElements: { start: "top 85%", end: "top 35%" },

    //Case Study
    caseStudySection: { start: "top 75%", end: "top 25%" },
    caseStudyContent: { start: "top 65%", end: "top 15%" },
    //Meaning
    meaningCards: { start: "top 75%", end: "top 35%" },
    meaningVerifiedContainer: { start: "top 65%", end: "top 15%" },

    //Idea Sequence
    ideaContainer: { start: "top 75%", end: "top 25%" },
    ideaContent: { start: "top 65%", end: "top 35%" },
    //CTA
    ctaContainer: { start: "top 65%", end: "top 35%" },
  },
};
