// withGsapAnimation.jsx
import React, { useEffect, useRef } from "react";
import { gsap, ScrollTrigger, MotionPathPlugin } from "./gsapConfig";
import axios from "axios";
gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

const withGsapAnimation = (WrappedComponent, config = {}) => {
  const WithGsapAnimation = (props) => {
    const isAlreadyLogged = (sectionName) => {
      const loggedSections =
        JSON.parse(localStorage.getItem("scroll_section")) || {};
      return !!loggedSections[sectionName];
    };

    const markSectionAsLogged = (sectionName) => {
      const loggedSections =
        JSON.parse(localStorage.getItem("scroll_section")) || {};
      loggedSections[sectionName] = true;
      localStorage.setItem("scroll_section", JSON.stringify(loggedSections));
    };
    const logScrollSection = async (sectionName) => {
      //local storage
      const user = localStorage.getItem("tempUserId");
      if (isAlreadyLogged(sectionName)) {
        return; // Avoid duplicates
      }
      if (["title", "SwipeRevealBox"].includes(sectionName)) return;
      try {
        await axios.post("/api/users/scroll-section", {
          sectionName,
          user,
        });
        markSectionAsLogged(sectionName);
        // console.log(`Scroll section: ${sectionName}`);
      } catch (error) {
        // console.error("Error logging scroll event:", error);
      }
    };
    const animationRef = useRef(null);

    useEffect(() => {
      const el = animationRef.current;
      if (!el) return;

      if (!config.animations || !Array.isArray(config.animations)) {
        console.warn("No 'animations' array found in config.");
        return;
      }

      const {
        defaultTriggerOptions = {
          scrub: 1,
          markers: false,
          toggleActions: "play none none reverse",
        },
      } = config;

      // Create a timeline for each animation in the config
      const timelines = config.animations.map((anim) => {
        const {
          target,
          from,
          to,
          start = "top 80%",
          end = "bottom 20%",
          trigger = el, // fallback to the container
          scrollTrigger = {},
          timeline,
          ...timelineOptions
        } = anim;

        // If a target is specified, query it inside our container `el`
        // Otherwise, animate `el` itself
        const targetEl = target ? el.querySelector(target) : el;

        // Merge defaults + any per-animation overrides
        const mergedTrigger = {
          trigger,
          start,
          end,
          ...defaultTriggerOptions,
          ...scrollTrigger,
          onEnter(self) {
            // ScrollTrigger passes the instance as the first parameter
            if (self.isActive) {
              logScrollSection(WithGsapAnimation.displayName);
            }
          },
        };

        const tl = gsap.timeline({
          scrollTrigger: mergedTrigger,
          ...timelineOptions,
        });

        // 4) If user provided a timeline callback, run that
        if (typeof timeline === "function") {
          timeline(tl, targetEl);
        } else {
          // Otherwise do a standard fromTo
          tl.fromTo(targetEl, from, to);
        }

        return tl;
      });

      // Cleanup
      return () => {
        timelines.forEach((tl) => {
          tl.scrollTrigger?.kill();
          tl.kill();
        });
      };
    }, []);

    return (
      <div ref={animationRef}>
        <WrappedComponent {...props} />
      </div>
    );
  };

  WithGsapAnimation.displayName =
    config.displayName ||
    `${WrappedComponent.displayName || WrappedComponent.name || "Component"}`;

  return WithGsapAnimation;
};

export default withGsapAnimation;
