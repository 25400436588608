import { gsap } from "./gsapConfig";
import { startstop } from "./startstop";
let isMobile = window.innerWidth < 768;

window.addEventListener("resize", () => {
  isMobile = window.innerWidth < 768;
});

// Updated helper function that accepts fallback values
const getTriggerPoints = (
  animationType,
  fallback = { start: "top 65%", end: "top 35%" }
) => {
  const deviceType = isMobile ? "mobile" : "desktop";
  return startstop[deviceType][animationType] || fallback;
};

export const animations = {
  bgColorChange: {
    target: ".biz-report-section-title",
    from: { backgroundColor: "#ffffff" },
    to: { backgroundColor: "#000", duration: 1, ease: "power2.inOut" },
    ...getTriggerPoints("bgColorChange", {
      start: "top 75%",
      end: "bottom 50%",
    }),
  },
  fade: {
    from: { opacity: 0 },
    to: { opacity: 1, duration: 1 },
    ...getTriggerPoints("fade", { start: "top 75%", end: "top 90%" }),
  },
  fadeUp: {
    from: { y: 50, opacity: 0 },
    to: { y: 0, opacity: 1, duration: 1 },
    ...getTriggerPoints("fadeUp", { start: "top 75%", end: "top 25%" }),
  },
  fadeDown: {
    from: { y: -50, opacity: 0 },
    to: { y: 0, opacity: 1, duration: 1 },
    ...getTriggerPoints("fadeDown", { start: "top 75%", end: "top 25%" }),
  },
  fadeLeft: {
    from: { x: 50, opacity: 0 },
    to: { x: 0, opacity: 1, duration: 1 },
    ...getTriggerPoints("fadeLeft", { start: "top 75%", end: "top 25%" }),
  },
  fadeRight: {
    from: { x: 50, opacity: 0 },
    to: { x: 0, opacity: 1, duration: 1 },
    ...getTriggerPoints("fadeRight", { start: "top 75%", end: "top 25%" }),
  },
  zoom: {
    from: { scale: 0.5, opacity: 0 },
    to: { scale: 1, opacity: 1, duration: 1 },
    ...getTriggerPoints("zoom", { start: "top 75%", end: "top 25%" }),
  },
  flip: {
    from: { rotationY: 180, opacity: 0 },
    to: { rotationY: 0, opacity: 1, duration: 1 },
    ...getTriggerPoints("flip", { start: "top 75%", end: "top 25%" }),
  },
  blockReveal: {
    from: { xPercent: -100, opacity: 0 },
    to: { xPercent: 0, opacity: 1, duration: 1, ease: "power2.out" },
    ...getTriggerPoints("blockReveal", { start: "top 75%", end: "top 25%" }),
  },
  boxSwipeReveal: {
    target: ".swipe-box",
    timeline: (tl, boxEl) => {
      const container = boxEl.closest(".swipe-reveal-container");
      const textEl = container.querySelector(".hidden-text");

      // Set initial state of text
      gsap.set(textEl, { opacity: 0 });

      tl.fromTo(
        boxEl,
        { xPercent: -100 },
        { xPercent: 0, duration: 1, ease: "power2.out" }
      )
        .to(boxEl, { duration: 0.5 }) // hold
        .to(textEl, { opacity: 1, duration: 2 }, ">")
        .to(
          boxEl,
          { xPercent: 100, duration: 1, ease: "power2.inOut" },
          "+=0.5"
        );
    },
    ...getTriggerPoints("boxSwipeReveal", { start: "top 90%", end: "top 50%" }),
  },
  underline: {
    target: ".underline",
    from: { scaleX: 0 },
    to: { scaleX: 1, transformOrigin: "left center", duration: 1 },
    ...getTriggerPoints("titleUnderline", {
      start: "top 75%",
      end: "bottom 50%",
    }),
  },
  reveal: {
    target: ".hidden-text",
    from: { opacity: 0 },
    to: { opacity: 1, duration: 1 },
    ...getTriggerPoints("titleReveal", {
      start: "top 75%",
      end: "bottom 100%",
    }),
  },
  comicPanelSequence: {
    timeline: (tl, containerEl) => {
      const bluePanel = containerEl.querySelector(".blue-panel");
      const orangePanel = containerEl.querySelector(".orange-panel");
      const tealPanel = containerEl.querySelector(".teal-panel");
      const firstPanelTriggerPoints = getTriggerPoints("comicFirstPanel", {
        start: "top 55%",
        end: "top 25%",
      });
      const normalPanelTriggerPoints = getTriggerPoints("comicNormalPanel", {
        start: "top 75%",
        end: "top 25%",
      });

      // Create separate ScrollTriggers for each panel
      gsap.fromTo(
        bluePanel,
        { x: -100, opacity: 0 },
        {
          x: 0,
          opacity: 1,
          duration: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: bluePanel,
            ...firstPanelTriggerPoints,
            toggleActions: "play none none reverse",
          },
        }
      );

      gsap.fromTo(
        orangePanel,
        { x: 100, opacity: 0 },
        {
          x: 0,
          opacity: 1,
          duration: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: orangePanel,
            ...normalPanelTriggerPoints,
            toggleActions: "play none none reverse",
          },
        }
      );

      gsap.fromTo(
        tealPanel,
        { x: -100, opacity: 0 },
        {
          x: 0,
          opacity: 1,
          duration: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: tealPanel,
            ...normalPanelTriggerPoints,
            toggleActions: "play none none reverse",
          },
        }
      );
    },
  },
  timelineSequence: {
    timeline: (tl, containerEl) => {
      const containers = containerEl.querySelectorAll(".timeline-container");
      const containerTriggerPoints = getTriggerPoints("timelineContainers", {
        start: "top 75%",
        end: "top 25%",
      });
      const verticalLineTriggerPoints = getTriggerPoints(
        "timelineVerticalLine",
        {
          start: "top 65%",
          end: "top 15%",
        }
      );
      const imageTriggerPoints = getTriggerPoints("timelineImage", {
        start: "top 60%",
        end: "top 10%",
      });
      const contentTextTriggerPoints = getTriggerPoints("timelineContentText", {
        start: "top 55%",
        end: "top 5%",
      });

      containers.forEach((container) => {
        gsap.fromTo(
          container,
          { opacity: 0 },
          {
            opacity: 1,
            duration: 1,
            ease: "power2.out",
            scrollTrigger: {
              trigger: container,
              ...containerTriggerPoints,
              toggleActions: "play none none reverse",
            },
          }
        );

        // Animate date circle
        // const dateCircle = container.querySelector(".timeline-date-circle");
        // gsap.fromTo(
        //   dateCircle,
        //   { scale: 0, opacity: 0 },
        //   {
        //     scale: 1,
        //     opacity: 1,
        //     duration: 1,
        //     ease: "power2.out",
        //     scrollTrigger: {
        //       trigger: container,
        //       start: "top 90%",
        //       end: "top 20%",
        //       toggleActions: "play none none reverse",
        //     },
        //   }
        // );

        // Animate vertical line with drawing effect
        const verticalLine = container.querySelector(".timeline-vertical-line");
        gsap.fromTo(
          verticalLine,
          { scaleY: 0, transformOrigin: "top center" },
          {
            scaleY: 1,
            duration: 1.5,
            ease: "power2.out",
            scrollTrigger: {
              trigger: container,
              ...verticalLineTriggerPoints,
              toggleActions: "play none none reverse",
            },
          }
        );

        // Animate image growth
        const image = container.querySelector(".timeline-image");
        gsap.fromTo(
          image,
          { scale: 0, opacity: 0 },
          {
            scale: 1,
            opacity: 1,
            duration: 1,
            ease: "power2.out",
            scrollTrigger: {
              trigger: container,
              ...imageTriggerPoints,
              toggleActions: "play none none reverse",
            },
          }
        );

        // Animate content text
        const contentText = container.querySelector(".timeline-content-text");
        gsap.fromTo(
          contentText,
          { opacity: 0, y: 20 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            ease: "power2.out",
            scrollTrigger: {
              trigger: container,
              ...contentTextTriggerPoints,
              toggleActions: "play none none reverse",
            },
          }
        );
      });
    },
  },
  presentDaySequence: {
    timeline: (tl, containerEl) => {
      // Get all sections
      const sections = containerEl.querySelectorAll(".present-section");
      const sectionTriggerPoints = getTriggerPoints("presentSections", {
        start: "top 85%", // Changed from 25% to 85%
        end: "top 55%",
      });
      const zoomTriggerPoints = getTriggerPoints("presentZoomElements", {
        start: "top 85%",
        end: "top 35%",
      });

      sections.forEach((section, index) => {
        // Create a reveal effect by sliding down
        gsap.fromTo(
          section,
          { clipPath: "inset(0 0 100% 0)" }, // Start fully hidden from bottom
          {
            clipPath: "inset(0 0 0% 0)", // Reveal to fully visible
            duration: 2,
            ease: "power2.out",
            scrollTrigger: {
              trigger: section,
              ...sectionTriggerPoints,
              toggleActions: "play none none reverse",
              // markers: true,  // Uncomment this line to debug trigger points
            },
          }
        );

        // Zoom in animation for elements with present-zoom-in class
        const zoomElements = section.querySelectorAll(".present-zoom-in");
        zoomElements.forEach((element) => {
          gsap.fromTo(
            element,
            { scale: 0.5, opacity: 0 },
            {
              scale: 1,
              opacity: 1,
              duration: 1,
              ease: "elastic.out(1, 0.5)",
              scrollTrigger: {
                trigger: element,
                ...zoomTriggerPoints,
                toggleActions: "play none none reverse",
              },
            }
          );
        });
      });
    },
  },
  caseStudySequence: {
    timeline: (tl, containerEl) => {
      // Get all case study sections
      const sections = containerEl.querySelectorAll(".case-study-section");
      const sectionTriggerPoints = getTriggerPoints("caseStudySection", {
        start: "top 75%",
        end: "top 25%",
      });
      const contentTriggerPoints = getTriggerPoints("caseStudyContent", {
        start: "top 65%",
        end: "top 15%",
      });

      sections.forEach((section) => {
        // Fade in the entire section
        gsap.fromTo(
          section,
          { opacity: 0 },
          {
            opacity: 1,
            duration: 1,
            ease: "power2.out",
            scrollTrigger: {
              trigger: section,
              ...sectionTriggerPoints,
              toggleActions: "play none none reverse",
            },
          }
        );

        // Flip down animation for the content
        const content = section.querySelector(".case-study-content");
        gsap.fromTo(
          content,
          {
            rotationX: -130,
            opacity: 0,
            y: -50,
          },
          {
            rotationX: 0,
            opacity: 1,
            y: 0,
            duration: 1,
            ease: "power2.out",
            scrollTrigger: {
              trigger: section,
              ...contentTriggerPoints,
              toggleActions: "play none none reverse",
            },
          }
        );
      });
    },
  },
  meaningSequence: {
    timeline: (tl, containerEl) => {
      // Get all meaning cards
      const cards = containerEl.querySelectorAll(".meaning-card");
      const cardTriggerPoints = getTriggerPoints("meaningCards", {
        start: "top 75%",
        end: "top 25%",
      });
      const verifiedContainerTriggerPoints = getTriggerPoints(
        "meaningVerifiedContainer",
        {
          start: "top 65%",
          end: "top 15%",
        }
      );
      cards.forEach((card) => {
        // Animate header with fade down right
        const header = card.querySelector(".meaning-header");
        gsap.fromTo(
          header,
          {
            y: -50,
            x: -50,
            opacity: 0,
          },
          {
            y: 0,
            x: 0,
            opacity: 1,
            duration: 1,
            ease: "power2.out",
            scrollTrigger: {
              trigger: card,
              ...cardTriggerPoints,
              toggleActions: "play none none reverse",
            },
          }
        );

        // Animate verified container with fade up left
        const verifiedContainer = card.querySelector(
          ".meaning-verified-container"
        );
        if (verifiedContainer) {
          gsap.fromTo(
            verifiedContainer,
            {
              y: 50,
              x: 50,
              opacity: 0,
            },
            {
              y: 0,
              x: 0,
              opacity: 1,
              duration: 1,
              ease: "power2.out",
              scrollTrigger: {
                trigger: card,
                ...verifiedContainerTriggerPoints,
                toggleActions: "play none none reverse",
              },
            }
          );
        }
      });
    },
  },
  ideaSequence: {
    timeline: (tl, containerEl) => {
      const containerTriggerPoints = getTriggerPoints("ideaContainer", {
        start: "top 75%",
        end: "top 25%",
      });
      const contentTriggerPoints = getTriggerPoints("ideaContent", {
        start: "top 85%",
        end: "top 35%",
      });

      // Diagonal wipe effect for the container
      gsap.fromTo(
        containerEl,
        {
          clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)",
        },
        {
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          duration: 1.2,
          ease: "power2.out",
          scrollTrigger: {
            trigger: containerEl,
            ...containerTriggerPoints,
            toggleActions: "play none none reverse",
          },
        }
      );

      // Why it works section
      const whySection = containerEl.querySelector(".why-it-works");
      gsap.fromTo(
        whySection,
        { x: -100, opacity: 0 },
        {
          x: 0,
          opacity: 1,
          duration: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: whySection,
            ...contentTriggerPoints,
            toggleActions: "play none none reverse",
          },
        }
      );
      // Slogan section
      const sloganSection = containerEl.querySelector(".idea-slogan");
      gsap.fromTo(
        sloganSection,
        { scale: 0.3, opacity: 0 },
        {
          scale: 1,
          opacity: 1,
          duration: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: sloganSection,
            ...contentTriggerPoints,
            toggleActions: "play none none reverse",
          },
        }
      );

      // Possibilities items
      const possibilityItems = containerEl.querySelectorAll(
        ".idea-possibilities-item"
      );
      possibilityItems.forEach((item) => {
        gsap.fromTo(
          item,
          { scale: 0.5, opacity: 0 },
          {
            scale: 1,
            opacity: 1,
            duration: 1,
            ease: "elastic.out(1, 0.5)",
            scrollTrigger: {
              trigger: item,
              ...contentTriggerPoints,
              toggleActions: "play none none reverse",
            },
          }
        );
      });
      //Skill breakdown items
      const skillBreakdownItems = containerEl.querySelectorAll(
        ".idea-skill-breakdown"
      );
      gsap.fromTo(
        skillBreakdownItems,
        { x: 100, opacity: 0 },
        {
          x: 0,
          opacity: 1,
          duration: 2,
          ease: "power2.out",
          scrollTrigger: {
            trigger: skillBreakdownItems,
            ...contentTriggerPoints,
            toggleActions: "play none none reverse",
          },
        }
      );

      // Slide in left elements
      // const slideLeftElements = containerEl.querySelectorAll(
      //   ".idea-slide-in-left"
      // );
      // slideLeftElements.forEach((element) => {
      //   gsap.fromTo(
      //     element,
      //     { x: -100, opacity: 0 },
      //     {
      //       x: 0,
      //       opacity: 1,
      //       duration: 1,
      //       ease: "power2.out",
      //       scrollTrigger: {
      //         trigger: element,
      //         start: "top 80%",
      //         end: "top 30%",
      //         toggleActions: "play none none reverse",
      //       },
      //     }
      //   );
      // });

      // Slide in right elements
      // const slideRightElements = containerEl.querySelectorAll(
      //   ".idea-slide-in-right"
      // );
      // slideRightElements.forEach((element) => {
      //   gsap.fromTo(
      //     element,
      //     { x: 100, opacity: 0 },
      //     {
      //       x: 0,
      //       opacity: 1,
      //       duration: 1,
      //       ease: "power2.out",
      //       scrollTrigger: {
      //         trigger: element,
      //         start: "top 80%",
      //         end: "top 30%",
      //         toggleActions: "play none none reverse",
      //       },
      //     }
      //   );
      // });

      // Launch plan section
      const launchPlan = containerEl.querySelector(".idea-launch-plan");
      gsap.fromTo(
        launchPlan,
        { scale: 0.5, opacity: 0 },
        {
          scale: 1,
          opacity: 1,
          duration: 1,
          ease: "power2.out", // Changed from elastic.out(1, 0.5) to power2.out for smooth zoom
          scrollTrigger: {
            trigger: launchPlan,
            ...contentTriggerPoints,
            toggleActions: "play none none reverse",
          },
        }
      );
    },
  },
  ctaSequence: {
    timeline: (tl, containerEl) => {
      const contentTriggerPoints = getTriggerPoints("ctaContainer", {
        start: "top 65%",
        end: "top 35%",
      });
      // Fade up animation for the container
      const ctaContainer = containerEl.querySelector(".report-cta-content");
      gsap.fromTo(
        ctaContainer,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 2,
          ease: "power2.out",
          scrollTrigger: {
            trigger: ctaContainer,
            ...contentTriggerPoints,
            toggleActions: "play none none reverse",
          },
        }
      );
    },
  },
};

export const defaultOptions = {
  scrub: 1,
  markers: false,
  toggleActions: "play none none reverse",
};
