import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://1453173157ecda27cd2c94434d5b27b2@o4508167906263040.ingest.us.sentry.io/4508168974434304",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import App from "./App.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import Homescreen from "./screens/HomeScreen.jsx";
import About from "./screens/About.jsx";
import NotFound from "./appwide/NotFound.jsx";
import LoginScreen from "./screens/LoginScreen.jsx";
import RegisterScreen from "./screens/RegisterScreen.jsx";
import FAQScreen from "./screens/FAQScreen";
import TermsScreen from "./screens/TermsScreen";
import PrivacyScreen from "./screens/PrivacyScreen";
import PrivateRoute from "./components/PrivateRoute";
import SubscribeLanding from "./screens/SubscribeLanding";
import Advertise from "./screens/Advertise";
import Contact from "./screens/Contact";
import Latest from "./screens/Latest";
import Subbed from "./screens/SubbedScreen";
import Feedback from "./screens/Feedback";
import Unsub from "./screens/Unsub";
import ViewSingle from "./screens/ViewSingle";
import Poll from "./screens/Polls/PollScreen.jsx";
import Archive from "./screens/Archive.jsx";
import CustomNL from "./screens/CustomNL/CustomNL.jsx";
import ProfileScreen from "./screens/ProfileScreen.jsx";
import ResetPassScreen from "./screens/ResetPassScreen.jsx";
import AfterPaymentScreen from "./screens/AfterPaymentScreen.jsx";
import QuizHomeScreen from "./screens/Playbook/QuizHomeScreen.jsx";
import BizQuiz from "./screens/BizQuiz/BizQuiz.jsx";
import ConfirmationQ from "./screens/BizQuiz/ConfirmationQ.jsx";
import BizReport from "./screens/BizQuiz/BizReport.jsx";
import LaunchPlan from "./screens/BizQuiz/LaunchPlan.jsx";
import { clarity } from "react-microsoft-clarity";

// Initialize Clarity with your project ID

if (process.env.NODE_ENV === "production") {
  try {
    clarity.init("oqt1n80t0b");
  } catch (err) {
    console.log("err");
  }
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<QuizHomeScreen />} />
      <Route path="/about" element={<About />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/register" element={<RegisterScreen />} />
      <Route path="/terms" element={<TermsScreen />} />
      <Route path="/privacy" element={<PrivacyScreen />} />
      <Route path="/faq" element={<FAQScreen />} />
      {/* <Route path="/sublanding" element={<SubscribeLanding />} /> */}
      <Route path="/advertise" element={<Advertise />} />
      <Route path="/contact" element={<Contact />} />
      {/* <Route path="/latest" element={<Latest />} /> */}
      {/* <Route path="/subbed" element={<Subbed />} /> */}
      {/* <Route path="/feedback" element={<Feedback />} /> */}
      {/* <Route path="/account" element={<Unsub />} /> */}
      {/* <Route path="/view" element={<ViewSingle />} /> */}
      {/* <Route path="/poll" element={<Poll />} /> */}
      {/* <Route path="/archive" element={<Archive />} /> */}
      {/* <Route path="/leadership-newsletter" element={<Homescreen />} /> */}
      {/* <Route path="/customize" element={<CustomNL />} /> */}
      {/* <Route path="/resetpass" element={<ResetPassScreen />} /> */}
      <Route path="/find-your-business-fit" element={<BizQuiz />} />
      <Route path="/personal-business-report" element={<BizReport />} />
      <Route path="/thank-you" element={<ConfirmationQ />} />
      <Route path="/launch-plan" element={<LaunchPlan />} />
      {/* Private Routes */}
      <Route path="" element={<PrivateRoute />}>
        <Route path="/profile" element={<ProfileScreen />} />
        <Route path="/registered-premium" element={<AfterPaymentScreen />} />
      </Route>
      <Route path="/*" element={<NotFound />} />
    </Route>
  )
);
ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);
