import React from "react";

const Customers = ({ style, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
    style={style}
    className={className}
  >
    <g id="Positive_Culture">
      <path
        style={{ fill: "currentColor" }}
        d="M63.9,47c-22.3,0-40.5,18.2-40.5,40.5S41.6,128,63.9,128s40.5-18.2,40.5-40.5S86.3,47,63.9,47z M63.9,124
					c-20.1,0-36.5-16.4-36.5-36.5S43.8,51,63.9,51c20.1,0,36.5,16.4,36.5,36.5S84.1,124,63.9,124z"
      />
      <path
        style={{ fill: "currentColor" }}
        d="M46.9,79.5c1.4-1.4,3.3-2.2,5.4-2.2c2,0,3.9,0.8,5.4,2.2l1.4,1.4l2.8-2.8l-1.4-1.4c-2.2-2.2-5.1-3.4-8.2-3.4
					c-3.1,0-6,1.2-8.2,3.4L42.6,78l2.8,2.8L46.9,79.5z"
      />
      <path
        style={{ fill: "currentColor" }}
        d="M76.8,73.2c-3.1,0-6,1.2-8.2,3.4L67.2,78l2.8,2.8l1.4-1.4c1.4-1.4,3.3-2.2,5.4-2.2c2,0,3.9,0.8,5.4,2.2
					l1.4,1.4l2.8-2.8L85,76.6C82.8,74.4,79.9,73.2,76.8,73.2z"
      />
      <path
        style={{ fill: "currentColor" }}
        d="M81.2,94H46.7c-1.1,0-2,0.9-2,2c0,10.6,8.6,19.2,19.2,19.2s19.2-8.6,19.2-19.2C83.2,94.9,82.3,94,81.2,94z
					 M63.9,111.2c-7.7,0-14.1-5.8-15.1-13.2H79C78,105.5,71.6,111.2,63.9,111.2z"
      />
      <path
        style={{ fill: "currentColor" }}
        d="M26,62.2L24,61.7c-11.5-2.9-19.5-13.2-19.5-25c0-14.2,11.6-25.8,25.8-25.8c14.2,0,25.8,11.6,25.8,25.8
					c0,2-0.2,4-0.7,5.9L55,44.5l3.9,0.9l0.5-1.9c0.5-2.2,0.8-4.5,0.8-6.8c0-16.4-13.4-29.8-29.8-29.8S0.5,20.3,0.5,36.7
					c0,13.7,9.2,25.6,22.5,28.9l1.9,0.5L26,62.2z"
      />
      <path
        style={{ fill: "currentColor" }}
        d="M26.8,32.7l2.8-2.8l-1.4-1.4c-1.7-1.7-3.9-2.6-6.3-2.6c-2.4,0-4.6,0.9-6.3,2.6l-1.4,1.4l2.8,2.8l1.4-1.4
					c0.9-0.9,2.2-1.4,3.5-1.4c1.3,0,2.6,0.5,3.5,1.4L26.8,32.7z"
      />
      <path
        style={{ fill: "currentColor" }}
        d="M44.5,32.7l2.8-2.8l-1.4-1.4c-1.7-1.7-3.9-2.6-6.3-2.6s-4.6,0.9-6.3,2.6l-1.4,1.4l2.8,2.8l1.4-1.4
					c0.9-0.9,2.2-1.4,3.5-1.4s2.6,0.5,3.5,1.4L44.5,32.7z"
      />
      <path
        style={{ fill: "currentColor" }}
        d="M30.3,53c4.7,0,9-2.8,10.7-7.2l0.7-1.9l-3.7-1.5l-0.7,1.9c-1.2,2.9-3.9,4.7-7,4.7c-2.8,0-5.3-1.5-6.6-4
					l-1-1.8l-3.5,1.9l1,1.8C22.2,50.7,26.1,53,30.3,53z"
      />
      <path
        style={{ fill: "currentColor" }}
        d="M95.7,0C78.1,0,63.9,14.3,63.9,31.8c0,3.7,0.6,7.4,1.9,10.9l0.7,1.9l3.8-1.4l-0.7-1.9c-1.1-3-1.7-6.2-1.7-9.5
					C67.9,16.5,80.3,4,95.7,4c15.3,0,27.8,12.5,27.8,27.8c0,13.2-9.4,24.7-22.4,27.3l-2,0.4l0.8,3.9l2-0.4c14.8-2.9,25.6-16,25.6-31.2
					C127.5,14.3,113.2,0,95.7,0z"
      />
      <path
        style={{ fill: "currentColor" }}
        d="M86.6,20.3c-2.5,0-4.9,1-6.7,2.8l-1.4,1.4l2.8,2.8l1.4-1.4c1-1,2.4-1.6,3.8-1.6s2.8,0.6,3.8,1.6l1.4,1.4
					l2.8-2.8l-1.4-1.4C91.5,21.3,89.1,20.3,86.6,20.3z"
      />
      <path
        style={{ fill: "currentColor" }}
        d="M105.6,24.3c1.4,0,2.8,0.6,3.8,1.6l1.4,1.4l2.8-2.8l-1.4-1.4c-1.8-1.8-4.1-2.8-6.7-2.8c-2.5,0-4.9,1-6.7,2.8
					l-1.4,1.4l2.8,2.8l1.4-1.4C102.8,24.9,104.2,24.3,105.6,24.3z"
      />
      <path
        style={{ fill: "currentColor" }}
        d="M84.2,41c0,6.3,5.2,11.5,11.5,11.5s11.5-5.2,11.5-11.5c0-1.1-0.9-2-2-2h-19C85.1,39,84.2,39.9,84.2,41z
					 M102.9,43c-0.9,3.2-3.8,5.5-7.2,5.5s-6.4-2.3-7.2-5.5H102.9z"
      />
    </g>
  </svg>
);

export default Customers;
