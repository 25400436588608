import React from "react";
import { report_elements } from "./report_elements";
import { bold_text } from "../../../utils/bizreport/boldtext";

const Timeline = () => {
  // Define theme colors from theme.txt
  const themeColors = {
    primary: "#6082D4",
    secondary: "#0DE000",
    teal: "#00B894",
    orange: "#FF9F1C",
    red: "#b80000",
  };

  return (
    <div className="timeline-wrapper">
      {/* ONE */}
      <div className="timeline-container timeline-container-left">
        <div className="timeline-marker-wrapper">
          <div
            style={{
              background: themeColors.primary,
              boxShadow: "0 4px 6px rgba(96, 130, 212, 0.2)",
            }}
            className="timeline-date-circle"
          >
            {report_elements.history.one.date}
          </div>
          <div
            className="timeline-vertical-line"
            style={{ background: themeColors.primary }}
          />
        </div>
        <div className="timeline-content">
          <div>
            <img
              className="timeline-image"
              src={report_elements.history.one.image}
              alt=""
            />
          </div>
          <div className="timeline-content-text">
            <div className="timeline-title">
              {report_elements.history.one.title}
            </div>
            <div>{bold_text(report_elements.history.one.text)}</div>
          </div>
        </div>
      </div>

      {/* TWO */}
      <div className="timeline-container timeline-container-right">
        <div className="timeline-content timeline-content-right">
          <div>
            <img
              className="timeline-image"
              src={report_elements.history.two.image}
              alt=""
            />
          </div>
          <div className="timeline-content-text">
            <div className="timeline-title">
              {report_elements.history.two.title}
            </div>
            <div>{bold_text(report_elements.history.two.text)}</div>
          </div>
        </div>
        <div className="timeline-marker-wrapper">
          <div
            style={{
              background: themeColors.red,
              boxShadow: "0 4px 6px rgba(0, 184, 148, 0.2)",
            }}
            className="timeline-date-circle"
          >
            {report_elements.history.two.date}
          </div>
          <div
            className="timeline-vertical-line"
            style={{ background: themeColors.red }}
          />
        </div>
      </div>

      {/* THREE */}
      <div className="timeline-container timeline-container-left">
        <div className="timeline-marker-wrapper">
          <div
            style={{
              background: themeColors.orange,
              boxShadow: "0 4px 6px rgba(255, 159, 28, 0.2)",
            }}
            className="timeline-date-circle"
          >
            {report_elements.history.three.date}
          </div>
          <div
            style={{ background: themeColors.orange }}
            className="timeline-vertical-line"
          />
        </div>
        <div className="timeline-content">
          <div>
            <img
              className="timeline-image"
              src={report_elements.history.three.image}
              alt=""
            />
          </div>
          <div className="timeline-content-text">
            <div className="timeline-title">
              {report_elements.history.three.title}
            </div>
            <div>{bold_text(report_elements.history.three.text)}</div>
          </div>
        </div>
      </div>

      {/* FOUR */}
      <div className="timeline-container timeline-container-right">
        <div className="timeline-content timeline-content-right">
          <div>
            <img
              className="timeline-image"
              src={report_elements.history.four.image}
              alt=""
            />
          </div>
          <div className="timeline-content-text">
            <div className="timeline-title">
              {report_elements.history.four.title}
            </div>
            <div>{bold_text(report_elements.history.four.text)}</div>
          </div>
        </div>
        <div className="timeline-marker-wrapper">
          <div
            style={{
              background: themeColors.teal,
              boxShadow: "0 4px 6px rgba(0, 184, 148, 0.2)",
            }}
            className="timeline-date-circle"
          >
            {report_elements.history.four.date}
          </div>
          <div
            className="timeline-vertical-line"
            style={{ background: themeColors.teal }}
          />
        </div>
      </div>
    </div>
  );
};

export default Timeline;
