export const combo = {
  v1: {
    //Hero Section -
    hero_img: "/images/lesson_de.jpg",
    bold_title: {
      whole_title: "Find Your Perfect Business Fit!",
      title: "Find Your ",
      span: "Perfect Business",
      title2: " Fit!",
    },
    //target group
    group_title: "The Path to Your Perfect Business", //"Helping Busy Professionals Win",
    //bullets
    value_blurb:
      "Discover the online business that [[fits you best]] and launch the perfect product",

    action1: "Take The Quiz!",
    heroAction: "Take The Free Quiz!",
    titleSection2: "Get Your Free Business Trend Report",
    blurbSection2: "Lets go ahead and get started",
    //What You'll get section
    step1: {
      img: "",
      title: "Start with the Quiz",
      text: "Take our free, 2-minute quiz to discover the type of business that aligns with your personality and strengths. It's quick, easy, and gives you instant insights to get started on your path to success.",
    },
    step2: {
      img: "",
      title: "Get Weekly Trend Reports",
      text: "Receive personalized weekly reports highlighting emerging business opportunities that align with your skillset. Each report includes market analysis and actionable launch strategies.",
    },
    step3: {
      img: "",
      title: "Launch Your Business",
      text: "Follow our tailored launch plans to turn your skills into a business. Get guidance on how to capitalize on current trends and market demands.",
    },
    //Why Now Section
    why_now_title:
      "Capitalize on the AI Boom and Build Your Online Business Today",
    why_now_intro:
      "The AI revolution is here, reshaping industries and creating endless opportunities for entrepreneurs. Now is the perfect time to harness AI and build a thriving online business that sets you apart.",
  },
  v2: {
    //Hero Section
    hero_img: "/images/lesson_de.jpg",
    bold_title: {
      whole_title: "Turn Your Skills Into a Thriving Business!",
      title: "Turn Your ",
      span: "Skills",
      title2: " Into a Thriving Business!",
    },
    //target group
    group_title: "Weekly Business Insights Matched to Your Skills",

    value_blurb:
      "Get personalized weekly reports on emerging business opportunities that match your unique skillset.",

    action1: "Start For $1",
    heroAction: "Get Your First Month for $1!",
    titleSection2: "Discover Your Business Potential",
    blurbSection2: "Let's match your skills to the right opportunity",

    //What You'll get section
    step1: {
      img: "",
      title: "Take the Skills Assessment",
      text: "Start with our quick quiz to identify your core strengths and expertise. We'll use this to match you with the most relevant business opportunities each week.",
    },
    step2: {
      img: "",
      title: "Get Weekly Trend Reports",
      text: "Receive personalized weekly reports highlighting emerging business opportunities that align with your skillset. Each report includes market analysis and actionable launch strategies.",
    },
    step3: {
      img: "",
      title: "Launch Your Business",
      text: "Follow our tailored launch plans to turn your skills into a profitable business. Get guidance on how to capitalize on current trends and market demands.",
    },
    //Why Now Section
    why_now_title: "Don't Miss Out on Today's Business Opportunities",
    why_now_intro:
      "The business landscape is evolving rapidly, creating new opportunities every week. Get early access to trending markets that match your skills and start your business with confidence.",
  },
};

export const stat_base = [
  {
    stat: "$15.7 Trillion",
    context: "AI's projected contribution to the economy by 2030.",
    img: "https://d2oa23hr7q6t96.cloudfront.net/66ccc34991c174e884be5df8/Id/3d07fc14f890e7f1d1e09d2f7ce218ea9368a9f1098ef32df339b765d6340989",
  },
  {
    stat: "83%",
    context: "Small businesses already boosting efficiency using AI.",
    img: "https://d2oa23hr7q6t96.cloudfront.net/66ccc34991c174e884be5df8/Id/3f458669d338c2a88848523ce520a78fcd2c0630f6f125dec8ab88875c119ff9",
  },
  {
    stat: "$8 Trillion",
    context: "Predicted global eCommerce market size by 2027.",
    img: "https://d2oa23hr7q6t96.cloudfront.net/66ccc34991c174e884be5df8/Id/4b11201f06b73387adb1b8f6ca801dbc2b95f7ea9c6cb0d0fb9f15860b8ecf82",
  },
  {
    stat: "77%",
    context:
      "Consumers willing to pay more for better service - AI can make it happen",
    img: "https://d2oa23hr7q6t96.cloudfront.net/66ccc34991c174e884be5df8/Id/6c9e39247fea7d32fa1167e9b66c4661c34e7ac0b378a26a8fdb480e0c336cc3",
  },
];

// Social media commerce is expected to reach $8.5 trillion by 2030
//Creator economy valued at $104.2B, expected to double by 2027.
//77% of consumers say they're willing to pay more for a small business with a better customer service experience
//65% of global businesses have adopted AI to reduce manual or repetitive tasks
//91% of small businesses using AI say it will help their business grow in the future
