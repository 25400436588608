import React from "react";

const OppIcon = ({ style, className }) => (
  <svg
    id="Expanded"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    style={style}
    className={className}
  >
    <style type="text/css">
      {`.cls-1{fill:currentColor;stroke-width:0px}`}
    </style>
    <path
      className="cls-1"
      d="m49.22,9.72h1.99c.55,0,1-.45,1-1s-.45-1-1-1h-.53v-.3c0-.55-.45-1-1-1s-1,.45-1,1v.36c-1.31.25-2.3,1.41-2.3,2.79,0,1.57,1.28,2.84,2.84,2.84.03,0,.05-.01.08-.02.03,0,.05.02.08.02.46,0,.84.38.84.84s-.38.84-.84.84h-1.99c-.55,0-1,.45-1,1s.45,1,1,1h1.3v.3c0,.55.45,1,1,1s1-.45,1-1v-.64c.91-.48,1.53-1.42,1.53-2.51,0-1.57-1.28-2.84-2.84-2.84-.03,0-.05.01-.08.02-.03,0-.05-.02-.08-.02-.46,0-.84-.38-.84-.84s.38-.84.84-.84Z"
    />
    <path
      className="cls-1"
      d="m57.69,12.4c0-4.63-3.77-8.4-8.4-8.4-2.82,0-5.31,1.4-6.83,3.53-3.27-2.31-7.1-3.53-11.15-3.53-10.74,0-19.47,8.73-19.47,19.47,0,.96.09,1.93.22,2.9-3.34,1.11-5.75,4.26-5.75,7.97,0,4.63,3.77,8.4,8.4,8.4,1.77,0,3.41-.55,4.76-1.49.75.75,1.54,1.43,2.36,2.04-.4.59-.63,1.29-.63,2.06,0,1.05.45,1.99,1.16,2.66-.71.67-1.16,1.61-1.16,2.66,0,1.93,1.51,3.5,3.4,3.63.47,3.22,3.25,5.71,6.6,5.71h.21c3.35,0,6.13-2.49,6.61-5.71,1.9-.13,3.4-1.7,3.4-3.63,0-1.05-.45-1.99-1.16-2.66.71-.67,1.16-1.61,1.16-2.66,0-.76-.24-1.47-.64-2.06,5.85-4.34,10-12.46,10-19.81,0-.92-.09-1.85-.22-2.77,4.03-.61,7.14-4.09,7.14-8.3ZM14.71,40.74c-3.53,0-6.4-2.87-6.4-6.4s2.87-6.4,6.4-6.4,6.4,2.87,6.4,6.4-2.87,6.4-6.4,6.4Zm16.7,17.26h-.21c-2.24,0-4.12-1.58-4.58-3.68h9.36c-.46,2.1-2.34,3.68-4.58,3.68Zm6.35-5.68h-12.91c-.91,0-1.66-.74-1.66-1.66s.74-1.66,1.66-1.66h12.91c.91,0,1.66.74,1.66,1.66s-.74,1.66-1.66,1.66Zm0-5.32h-12.91c-.91,0-1.66-.74-1.66-1.66s.74-1.66,1.66-1.66h12.91c.91,0,1.66.74,1.66,1.66s-.74,1.66-1.66,1.66Zm1.41-5.03c-.44-.18-.91-.29-1.41-.29h-2.92v-16.35c0-1.7,1.18-3.76,2.42-4.24.26-.1,1.04-.4,2.1.8.37.41,1,.45,1.41.08.41-.37.45-1,.08-1.41-1.73-1.94-3.44-1.67-4.31-1.34-2.22.85-3.71,3.84-3.71,6.11v16.35h-3.06v-16.35c0-2.27-1.49-5.26-3.71-6.11-.87-.33-2.58-.6-4.31,1.34-.37.41-.33,1.04.08,1.41.41.37,1.04.33,1.41-.08,1.07-1.2,1.85-.9,2.1-.8,1.25.47,2.42,2.53,2.42,4.24v16.35h-2.92c-.5,0-.98.1-1.42.29-.86-.6-1.69-1.28-2.47-2.05,1.33-1.49,2.14-3.44,2.14-5.59,0-4.63-3.77-8.4-8.4-8.4-.23,0-.46.02-.69.03-.11-.84-.18-1.68-.18-2.5,0-9.63,7.84-17.47,17.47-17.47,3.71,0,7.22,1.15,10.19,3.3-.38.96-.6,2-.6,3.1,0,4.38,3.37,7.98,7.65,8.36.14.9.23,1.81.23,2.71,0,6.94-4.01,14.61-9.6,18.5Zm10.12-23.17c-3.53,0-6.4-2.87-6.4-6.4s2.87-6.4,6.4-6.4,6.4,2.87,6.4,6.4-2.87,6.4-6.4,6.4Z"
    />
    <path
      className="cls-1"
      d="m14.63,31.65h1.99c.55,0,1-.45,1-1s-.45-1-1-1h-.53v-.3c0-.55-.45-1-1-1s-1,.45-1,1v.36c-1.31.25-2.3,1.41-2.3,2.79,0,1.57,1.28,2.84,2.84,2.84.03,0,.05-.01.08-.02.03,0,.05.02.08.02.46,0,.84.38.84.84s-.38.84-.84.84h-1.99c-.55,0-1,.45-1,1s.45,1,1,1h1.3v.3c0,.55.45,1,1,1s1-.45,1-1v-.64c.91-.48,1.53-1.42,1.53-2.51,0-1.57-1.28-2.84-2.84-2.84-.03,0-.05.01-.08.02-.03,0-.05-.02-.08-.02-.46,0-.84-.38-.84-.84s.38-.84.84-.84Z"
    />
  </svg>
);

export default OppIcon;
