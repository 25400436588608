import React from "react";
import "../../../assets/styling/reportsections.css";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import OppIcon from "./icons/opp";
import WinIcon from "./icons/win";
const Meaning = ({ meaning }) => {
  return (
    <div className="meaning-container">
      <div className="meaning-card">
        <div className="meaning-header">
          <div className="meaning-icon">
            <OppIcon style={{ width: "3em", color: "rgb(227 235 255)" }} />
          </div>
          <div className="meaning-content">
            <h3 className="meaning-title">Your Opportunity</h3>
            <div className="meaning-text">
              <p className="meaning-text-primary">
                {meaning.opportunity ? meaning.opportunity.a : ""}
              </p>
            </div>
          </div>
        </div>
        <div className="meaning-verified-container">
          <VerifiedRoundedIcon
            className="verified-icon"
            sx={{ fontSize: "3rem" }}
          />
          <p className="meaning-text-secondary bordered-text">
            {meaning.opportunity ? meaning.opportunity.b : ""}
          </p>
        </div>
      </div>

      {/* <div className="meaning-card">
        <div className="meaning-header">
          <div className="meaning-icon">
            <img
              src="/path-to-importance-icon.svg"
              alt="Why It Matters"
              className="meaning-icon-img"
            />
          </div>
          <div className="meaning-content">
            <h3 className="meaning-title">Why It Matters</h3>
            <div className="meaning-text">
              <p className="meaning-text-primary">
                As consumers become more health-conscious and research-driven,
                having a reliable digital solution that cuts through
                misinformation is essential.
              </p>
              <p className="meaning-text-secondary">
                Helping customers choose clean, effective oral care products
                fosters trust and loyalty in your brand.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="meaning-card">
        <div className="meaning-header">
          <div className="meaning-icon">
            <WinIcon style={{ width: "3em", color: "rgb(227 235 255)" }} />
          </div>
          <div className="meaning-content">
            <h3 className="meaning-title">How You'll Win</h3>
            <div className="meaning-text">
              <p className="meaning-text-primary">
                {meaning.win ? meaning.win.a : ""}
              </p>
            </div>
          </div>
        </div>
        <div className="meaning-verified-container">
          <VerifiedRoundedIcon
            className="verified-icon"
            sx={{ fontSize: "3rem" }}
          />
          <p className="meaning-text-secondary bordered-text">
            {meaning.win ? meaning.win.b : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Meaning;
