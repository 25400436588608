import React from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/styling/cta.css";

const ReportCTA = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    // Scroll current page to top before navigation
    window.scrollTo(0, 0);
    navigate("/personal-business-report");
  };

  return (
    <div className="quiz-cta-container">
      <div className="quiz-cta-content">
        <div className="quiz-cta-image">
          <img
            src="https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Misc/20a6de801474686253442646d08707c279765a6abeb10cd31dfa5525fd841e17"
            alt="View Report"
          />
        </div>
        <h2>turn your skills into profit</h2>
        <p>
          Get <strong>instant access</strong> to a custom business report made
          for <strong>your skillset</strong>. Unlock insights and start cashing
          in!
        </p>
        <button onClick={handleNavigate}>View Your Free Report</button>
        {/* time left */}
        <div className="time-left">{/* <p>Time left: 00:00:00</p> */}</div>
      </div>
    </div>
  );
};

export default ReportCTA;
