import React from "react";

const Marketing = ({ style, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 256"
    style={style}
    className={className}
  >
    <g id="_x31_4">
      <path
        style={{ fill: "currentColor" }}
        d="M233.055,94.824v-78c0-4.512-1.749-8.71-4.932-11.892C224.94,1.749,220.743,0,216.266,0c-4.512,0-8.709,1.749-11.892,4.932c-3.183,3.183-4.932,7.38-4.932,11.892v8.919c-5.911,15.705-55.439,29.626-85.975,34.907H60.266c-15.74,0-30.535,6.121-41.658,17.244S1.364,103.813,1.364,119.518c0,15.74,6.121,30.535,17.244,41.658c11.123,11.123,25.918,17.244,41.658,17.244h2.169v59.112c0,4.932,1.924,9.584,5.421,13.047c3.498,3.498,8.115,5.421,13.047,5.421h9.899c4.932,0,9.549-1.924,13.047-5.386c3.498-3.498,5.422-8.15,5.422-13.082V178.42h4.197c30.535,5.247,80.028,19.203,85.975,34.908v8.919c0,4.512,1.749,8.709,4.932,11.892c3.183,3.183,7.38,4.932,11.892,4.932c4.477,0,8.674-1.749,11.857-4.932c3.183-3.183,4.932-7.38,4.932-11.892V147.43c12.312-2.448,21.581-13.291,21.581-26.303C254.636,108.115,245.367,97.273,233.055,94.824z M98.776,237.532c0,2.134-0.84,4.127-2.344,5.631c-1.504,1.504-3.498,2.343-5.631,2.343h-9.899c-2.134,0-4.127-0.839-5.631-2.343c-1.504-1.504-2.343-3.498-2.343-5.631V178.42h25.848V237.532z M107.416,167.997l-47.15-0.07c-12.942,0-25.079-5.037-34.208-14.166c-9.164-9.164-14.201-21.301-14.201-34.243c0-12.907,5.037-25.079,14.201-34.208c9.129-9.129,21.266-14.166,34.208-14.166h47.15V167.997z M199.441,197.518c-21.406-16.894-67.751-26.443-81.532-28.961V70.55c13.781-2.554,60.126-12.102,81.532-28.996V197.518z M222.561,222.247c0,1.679-0.665,3.288-1.854,4.477c-2.378,2.378-6.541,2.378-8.919,0c-1.189-1.189-1.854-2.798-1.854-4.477V16.824c0-1.679,0.665-3.288,1.854-4.477c1.189-1.189,2.763-1.854,4.477-1.854c1.679,0,3.253,0.665,4.442,1.854c1.189,1.189,1.854,2.798,1.854,4.477V222.247z M233.055,136.587v-30.92c6.436,2.204,11.088,8.29,11.088,15.46C244.143,128.297,239.491,134.419,233.055,136.587z"
      />
    </g>
  </svg>
);

export default Marketing;
