import React from "react";
import OutlinedFlagRoundedIcon from "@mui/icons-material/OutlinedFlagRounded";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import "../../../assets/styling/case.css";
import Customers from "./icons/customers";
import RevenueIcon from "./icons/revenue2";
import MissionIcon from "./icons/mission";
import OperationsIcon from "./icons/operations";
import MarketingIcon from "./icons/marketing";
import { report_elements } from "./report_elements";
import { bold_text } from "../../../utils/bizreport/boldtext";
const Case = () => {
  const case_colors = {
    mission: {
      dark: "#1e2c8e",
      light: "#add9e8",
    },
    operations: {
      dark: "#106d58",
      light: "#c9ffe1",
    },
    marketing: {
      dark: "#af2c32",
      light: "#eed0d0",
    },
    customers: {
      dark: "#ed9011",
      light: "#fbe3cd",
    },
    revenue: {
      dark: "#6323a0",
      light: "#ded0ec",
    },
  };
  return (
    <div className="case-study-container">
      <div className="case-study-header">
        <span className="case-eyebrow">Exhibit A</span>
        <img
          className="case-study-image"
          src={report_elements.case.company_logo}
          alt="Case Company logo"
        />
        <h2 className="case-title">{report_elements.case.company_name}</h2>
        <div className="case-subtitle-wrapper">
          <div className="case-subtitle-line"></div>
          <p className="case-subtitle">
            Let's take a look at a company currently capitalizing on this trend.
          </p>
        </div>
      </div>

      {/* Section 1 Context */}
      <div className="case-study-section">
        <div
          className="case-study-icon-box"
          style={{
            backgroundColor: case_colors.mission.dark,
            color: case_colors.mission.light,
          }}
        >
          {/* <OutlinedFlagRoundedIcon /> */}
          <MissionIcon style={{ color: case_colors.mission.light }} />
          <h3 className="case-section-title">Their Mission</h3>
        </div>
        <div
          className="case-study-content"
          style={{
            color: case_colors.mission.dark,
            backgroundColor: case_colors.mission.light,
          }}
        >
          <div className="case-study-text-header">
            {report_elements.case.mission.title}
          </div>
          <p className="case-text">
            {bold_text(report_elements.case.mission.text)}
          </p>
        </div>
      </div>

      {/* Section 2 Operations */}
      <div className="case-study-section reverse">
        <div
          className="case-study-icon-box"
          style={{
            backgroundColor: case_colors.operations.dark,
            color: case_colors.operations.light,
          }}
        >
          {/* <AccountTreeRoundedIcon /> */}
          <OperationsIcon style={{ color: case_colors.operations.light }} />
          <h3>How They Do It</h3>
        </div>
        <div
          className="case-study-content"
          style={{
            color: case_colors.operations.dark,
            backgroundColor: case_colors.operations.light,
          }}
        >
          <div className="case-study-text-header">
            {report_elements.case.operations.title}
          </div>
          <p className="case-text">
            {bold_text(report_elements.case.operations.text)}
          </p>
        </div>
      </div>

      {/* Section 3 Marketing */}
      <div className="case-study-section">
        <div
          className="case-study-icon-box"
          style={{
            backgroundColor: case_colors.marketing.dark,
            color: case_colors.marketing.light,
          }}
        >
          {/* <CampaignRoundedIcon /> */}
          <MarketingIcon
            style={{ color: case_colors.marketing.light, width: "75%" }}
          />
          <h3>How They Market</h3>
        </div>
        <div
          className="case-study-content"
          style={{
            color: case_colors.marketing.dark,
            backgroundColor: case_colors.marketing.light,
          }}
        >
          {" "}
          <div className="case-study-text-header">
            {report_elements.case.marketing.title}
          </div>
          <p className="case-text">
            {bold_text(report_elements.case.marketing.text)}
          </p>
        </div>
      </div>

      {/* Section 4 Traffic */}
      <div className="case-study-section reverse">
        <div
          className="case-study-icon-box"
          style={{
            backgroundColor: case_colors.customers.dark,
            color: case_colors.customers.light,
          }}
        >
          <Customers style={{ color: case_colors.customers.light }} />
          <h3>Their Customers</h3>
        </div>
        <div
          className="case-study-content"
          style={{
            color: case_colors.customers.dark,
            backgroundColor: case_colors.customers.light,
          }}
        >
          <div className="case-study-number">
            {report_elements.case.customers.title}
          </div>
          <p className="case-text">
            {bold_text(report_elements.case.customers.text)}
          </p>
        </div>
      </div>

      {/* Section 5 Revenue */}
      <div className="case-study-section">
        <div
          className="case-study-icon-box"
          style={{
            backgroundColor: case_colors.revenue.dark,
            color: case_colors.revenue.light,
          }}
        >
          {/* <AttachMoneyRoundedIcon /> */}
          <RevenueIcon style={{ color: case_colors.revenue.light }} />
          <h3>Their Revenue</h3>
        </div>
        <div
          className="case-study-content"
          style={{
            color: case_colors.revenue.dark,
            backgroundColor: case_colors.revenue.light,
          }}
        >
          <div className="case-study-number">
            {report_elements.case.revenue.title}
          </div>
          <p className="case-text">
            {bold_text(report_elements.case.revenue.text)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Case;
